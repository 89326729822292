/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";

import css from "./style.less";

export default function ContentBlock(props){
  const {content}=props;

  const content_array=useMemo(()=>{
    return content.split(/(%%)/ig).filter(Boolean).filter((element)=>{
      return element!=="%%";
    });
  },[content]);

  return (
    <div className={css.content_block}>
      {content_array.map((element)=>{
        if(!isNaN(element)){
          return (
            <span key={element} className={css.strong_number}>{element}</span>
        )}
        return (
          <span key={element}>{element}</span>
      )})}
    </div>
)}


ContentBlock.propTypes={


};
ContentBlock.defaultProps={


};