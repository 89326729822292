
export default {
  "智慧海洋":{
    "主题部分":{
      "标题":"智慧海洋",
      "副标题":"",
      "描述":""
    },
    "模块描述1":{
      "标题":"我们的海洋正在发生的",
      "副标题":"信息感知，互联互通",
      "描述":"海洋地理，海洋生物，海洋化学，海洋交通，海洋环境及海洋地质等全域感知，开放兼容的海洋大数据云平台，海洋综合信息协同互动。"
    },
    "分割模块1":{
      "标题":"SMART OCEAN",
      "副标题":"化海洋为棋局，洞察变化自如应变。",
      "描述":"海洋资源开发、国际合作更明朗"
    },
    "模块描述2":{
      "标题":"我们关注海洋气候、经济动向",
      "副标题":"智能服务，享受海洋",
      "描述":"实时监测监控，为海洋活动，防灾减灾，海洋经济发展提供多元化的服务信息，提升海洋管理能力。"
    }
  }
};