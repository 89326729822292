
export default {
  "碳中和":{
    "主题部分":{
      "标题":"聚焦碳中和",
      "副标题":"",
      "描述":""
    },
    "模块描述1":{
      "标题":"应对气候问题，实现可持续发展",
      "副标题":"绿水青山就是金山银山",
      "描述":"坚持人与自然和谐共生，必须树立和践行绿水青山就是金山银山的理念，坚持节约资源和保护环境的基本国策，像对待生命一样对待生态环境，实行最严格的生态环境保护制度，形成绿色发展方式和生活方式，坚定走生产发展、生活富裕、生态良好的文明发展道路，建设美丽中国，为人民创造良好的生产生活环境，为全球生态安全作出贡献。"
    },
    "分割模块1":{
      "标题":"CARBON EMISSION MONITORING",
      "副标题":"即刻改变视角，关注地球升温",
      "描述":"STAR.VISION全面支持碳排放监测与预警"
    },
    "模块描述2":{
      "标题":"详实可靠的数据",
      "副标题":"落实生态资源配额交易机制",
      "描述":"提供详实可靠的数据，精确定量监测碳排放量，确保碳配额交易有效落地。"
    }
  }
};