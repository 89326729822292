/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
import useWindowSize from "@/hooks/useWindowSize";
import css from "./style.less";

export default function ProductionContentBanner(props){

  const {bg_src,title,sub_title,discription_title,discription_content,style,className}=props;

  const {height}=useWindowSize();

  const {isMobile,isDesktop}=useResponsive();

  const compose_class=useMemo(()=>classnames(css.production_content_banner,className),[className]);

  const effective_content_container_class=useMemo(()=>classnames({
    [css.effective_content_container_mobile]:isMobile,
    [css.effective_content_container_desktop]:isDesktop
  }),[isMobile,isDesktop]);

  const effective_content_class=useMemo(()=>classnames({
    [css.effective_content]:true,
    [css.effective_content_mobile]:isMobile,
    [css.effective_content_desktop]:isDesktop,
  }),[isMobile,isDesktop]);

  const compose_style=useMemo(()=>Object.assign({},{
    minHeight:height,
    backgroundImage:`url(${bg_src})`
  },style),[bg_src,height,style]);


  return (
    <div style={compose_style} className={compose_class}>
      <div className={effective_content_container_class}>
        <div className={effective_content_class}>
          <section className={css.title_block}>
            <p>{title}</p>
            <p>{sub_title}</p>
          </section>
          <div className={css.divider} />
          <section className={css.subject_block}>
            <p>{discription_title}</p>
            <p>{discription_content}</p>
          </section>
        </div>
      </div>
    </div>
)}


ProductionContentBanner.propTypes={


};
ProductionContentBanner.defaultProps={


};