/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React,{useRef,useState,useCallback} from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";
import useWindowSize from "@/hooks/useWindowSize";

import css from "./style.less";

export default function VideoBanner(props){
  const {source,initial_image,children}=props;
  const video_element=useRef();
  const canvas_element=useRef();
  const {width,height}=useWindowSize();
  const [is_load,set_is_load]=useState(false);


  const handlePlayed=useCallback(()=>{
    video_element.current.play();
    const canvas_context=canvas_element.current.getContext("2d");
    (function callback(){
      if(video_element.current){
        set_is_load(true);
        canvas_context.drawImage(video_element.current,0,0,1920,1080);
        requestAnimationFrame(callback);
      }
    })();
  },[]);

  return (
    <div style={{height}} className={css.canvas_banner_container}>
      {is_load?null:(
        <div className={css.initial_image} style={{backgroundImage:`url(${initial_image})`}} />
      )}
      <canvas
        ref={canvas_element}
        width={1920}
        height={1080}
        className={css.canvas_banner}
        style={width/height>1.65?{width}:{height}}
      />
      <video
        loop
        muted
        autoPlay
        playsInline
        preload="auto"
        ref={video_element}
        style={{display:"none",padding:0,margin:0}}
        onCanPlay={handlePlayed}
      >
        <source src={source} type="video/mp4" />
      </video>
      <div className={css.canvas_banner_content}>{children}</div>
    </div>
)}


VideoBanner.propTypes={


};
VideoBanner.defaultProps={


};