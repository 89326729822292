

export default [{
  name:"导航栏部分.主页",
  link:"./welcome"
},{
  name:"导航栏部分.产品",
  discription:"导航栏部分.产品[描述]",
  sub_navigation:[{
    name:"导航栏部分.产品[望江山一代机]",
    discription:"导航栏部分.产品[望江山一代机][描述]",
    link:"/productions/wang_jiang_shan"
  },{
    name:"导航栏部分.产品[下一代AI卫星平台]",
    discription:"导航栏部分.产品[下一代AI卫星平台][描述]",
    link:"/productions/ai_satellite"
  },{
    name:"导航栏部分.产品[卫星遥感数据]",
    discription:"导航栏部分.产品[卫星遥感数据][描述]",
    link:"/productions/satellite"
  }]
},{
  name:"导航栏部分.智慧地球",
  discription:"导航栏部分.智慧地球[描述]",
  sub_navigation:[{
    name:"导航栏部分.智慧地球[智慧海洋]",
    link:"/smart_earth/smart_ocean"
  },{
    name:"导航栏部分.智慧地球[智慧城市]",
    link:"/smart_earth/smart_city"
  },{
    name:"导航栏部分.智慧地球[应急管理]",
    link:"/smart_earth/emergency_management"
  },{
    name:"导航栏部分.智慧地球[精准农林]",
    link:"/smart_earth/agriculture_forestry"
  },{
    name:"导航栏部分.智慧地球[环境保护]",
    link:"/smart_earth/environmental_protection"
  },{
    name:"导航栏部分.智慧地球[碳中和]",
    link:"/smart_earth/carbon_neutral"
  },{
    name:"导航栏部分.智慧地球[保险期货]",
    link:"/smart_earth/finance_insurance"
  }]
},{
  name:"导航栏部分.展示",
  href:"https://star.vision/skyview/",
  discription:"导航栏部分.展示[描述]",
},{
  name:"导航栏部分.拓展合作",
  discription:"导航栏部分.拓展合作[描述]",
  sub_navigation:[{
    name:"导航栏部分.拓展合作[航天体验中心]",
    link:"/extend_cooperation/space_flight"
  },{
    name:"导航栏部分.拓展合作[产业园区]",
    link:"/extend_cooperation/industrial_park"
  },{
    name:"导航栏部分.拓展合作[联合实验室]",
    link:"/extend_cooperation/united_laboratory"
  }]
},{
  name:"导航栏部分.关于STAR.VISION",
  discription:"导航栏部分.关于STAR.VISION.描述",
  link:"/about_company"
}];