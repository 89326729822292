
export default {
  "精准农林":{
    "主题部分":{
      "标题":"FINANCE AND INSURANCE",
      "副标题":"",
      "描述":""
    },
    "模块描述1":{
      "标题":"MODERN SATELLITE TECHNOLOGY HELPS AGRICULTURE AND FORESTRY",
      "副标题":"Deep insight and precision maintenance",
      "描述":"High resolution and multi-spectral images provide more information invisible to the naked eye, allowing you to understand the soil state at any time, manage the growth of various crops accurately, and provide assistance for high yield"
    },
    "模块分割1":{
      "标题":"AGRICULTURE AND FORESTRY",
      "副标题":"Increase crop production and protect forest",
      "描述":"Big data drives precise agriculture and smart city"
    },
    "模块描述2":{
      "标题":"Modern satellite technology helps you take precautions",
      "副标题":"Dynamic monitoring and precise supervision",
      "描述":"All day and all-weather dynamic monitoring helps daily management of agriculture and forestry. Comprehensive analysis of multi-spectral information enables you to understand fire and pest disasters at a glance."
    }
  }
};