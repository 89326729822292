/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
import css from "./style.less";

export default function DiscriptionLayoutBlock(props){
  
  const {color,title,sub_title,discription,style,className}=props;

  const {isMobile,isDesktop}=useResponsive();

  const compose_style=useMemo(()=>Object.assign({},{color},style),[color,style]);

  const divider_style=useMemo(()=>Object.assign({},{backgroundColor:color}),[color]);

  const compose_class=useMemo(()=>classnames({
    [css.discription_layout_block]:true,
    [css.discription_layout_block_mobile]:isMobile,
    [css.discription_layout_block_desktop]:isDesktop
  },className),[className,isMobile,isDesktop]);

  return (
    <div style={compose_style} className={compose_class}>
      <div className={css.title}>
        {title}
      </div>
      {sub_title?(
        <div className={css.sub_title}>
          {sub_title}
        </div>
      ):null}
      {sub_title?(
        <div className={css.divider} style={divider_style} />
      ):null}
      <p>{discription}</p>
    </div>
)}


DiscriptionLayoutBlock.propTypes={


};
DiscriptionLayoutBlock.defaultProps={
  color:"white"
};