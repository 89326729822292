
export default {
  "联合实验室":{
    "主题部分":{
      "标题":"联合实验室",
      "副标题":"UNITED LABORATORY"
    },
    "模块简介1":{
      "标题":"联合实验室",
      "副标题":"合作探索，开放包容",
      "描述":"联合合作单位开展微小航天器前瞻性、先导性和探索性的重大技术研究，包括智能模块化可扩展微纳卫星平台、大功率能源获取技术、激光通信技术、高比冲微型推进技术，太空智能化研究等。"
    },
    "模块简介2":{
      "标题":"联合实验室",
      "副标题":"融合创新，共享转化",
      "描述":"融合空间技术与IT新兴技术，不断创新发展，快速转化研究成果成关键航天产品，并促进数字孪生城市、智能驾驶、大应急体系建设等大数据应用场景的研究与应用示范落地。"
    }
  }
};