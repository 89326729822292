/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";

import useProgressiveImg from "@/hooks/useProgressiveImg";
import VideoBanner from "../../elements/VideoBanner";
import BannerGhostButton from "../../elements/BannerGhostButton";

import VerticalBlock from "../../layouts/VerticalBlock";

import css from "./style.less";
import source from "../../assets/index-after.mp4";
import video_banner_init from "../../assets/video_banner_init.jpg";
import video_banner_init_low from "../../assets/video_banner_init.low.jpg";


export default function Banner2(){
  const {t}=useTranslation();
  const [current_video_banner_init]=useProgressiveImg({lowQualitySrc:video_banner_init_low,highQualitySrc:video_banner_init});

  return (
    <VideoBanner source={source} initial_image={current_video_banner_init} className={css.banner2}>
      <VerticalBlock>
        <div className={css.banner2_title}>
          {t("轮播图部分.轮播图2.标题文字")}
        </div>
        <BannerGhostButton link="/productions/wang_jiang_shan">
          {t("轮播图部分.轮播图2.按钮文字")}
        </BannerGhostButton>
      </VerticalBlock>
    </VideoBanner>
)}


Banner2.propTypes={


};
Banner2.defaultProps={


};