/* eslint-disable react/prop-types */
import classnames from "classnames";
import {useLocation} from "react-router-dom";
import React,{useMemo,useState,useEffect,useCallback} from "react";
// import propTypes from "prop-types";

import FlexCol from "@/components/FlexCol";

import TopBlock from "./components/TopBlock";
import MobileNavigate from "./components/MobileNavigate";


import css from "./style.less";
import navigation from "@/layouts/configs/navigation";


export default function MobileHeader(){

  const {key}=useLocation();

  const [toggle_state,set_toggle_state]=useState(false);

  const navigate_topbar_class=useMemo(()=>classnames({
    [css.navigate_topbar]:true,
    [css.navigate_topbar_open]:toggle_state,
    [css.navigate_topbar_close]:!toggle_state
  }),[toggle_state]);

  useEffect(()=>{
    set_toggle_state(false);
  },[key]);

  useEffect(()=>{
    if (toggle_state) {
      document.body.style.overflow="hidden";
    } else {
      document.body.style.overflow="scroll";
    }
  },[toggle_state]);

  const handleToggleState=useCallback(()=>{
    set_toggle_state(!toggle_state);
  },[toggle_state]);

  return (
    <FlexCol justify="start" className={navigate_topbar_class}>
      <TopBlock
        toggle_state={toggle_state}
        onToggleState={handleToggleState}
      />
      <MobileNavigate
        navigation={navigation}
        toggle_state={toggle_state}
      />
    </FlexCol>
)}


MobileHeader.propTypes={


};
MobileHeader.defaultProps={


};