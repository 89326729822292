
export default {
  "航天体验中心":{
    "主题部分":{
      "标题":"",
      "副标题":"SPACE EXPERIENCE CENTER"
    },
    "模块简介1":{
      "标题":"Realize the space dream",
      "副标题":"Extraordinary experience through VR interaction",
      "描述":"Virtual Reality technology is used to realize immersive interaction, truly simulate the weightlessness environment in space, create a variety of realistic space environment such as the moon, universe, space station, etc., and provide the visiter with an extraordinary space experience"
    },
    "模块简介2":{
      "标题":"Inspire the future",
      "副标题":"Rich program enables education through entertainment",
      "描述":"Propagate science through entertainment. With the help of rich programs such as the brief introduction of space industry history, simulated launch, planetary exploration, space travel and etc., the experiencer can understand the fantastic space world and space knowledge in the fun. Through rich interactive experience of science and technology, the experiencer can feel the charm of aerospace and inherit the dream of conquering the Starry Sea."
    }
  }
};