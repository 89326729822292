/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
// import propTypes from "prop-types";

// import css from "./style.scss";
import css from "./style.less";

export default function ContentImageBlock(props){

  const {image_src,style,className}=props;

  const compose_style=useMemo(()=>Object.assign({},style),[style]);

  const compose_class=useMemo(()=>classnames(css.block3_bg_content_image_container,className),[className]);

  return (
    <div style={compose_style} className={compose_class}>
      <img src={image_src} alt="" />
    </div>
)}


ContentImageBlock.propTypes={


};
ContentImageBlock.defaultProps={


};