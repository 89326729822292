/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
// import classnames from "classnames";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
import useWindowSize from "@/hooks/useWindowSize";
import css from "./style.less";

export default function ThemeContentBlock({bg_src,icon,title,discription,no_block,style,className}){
  
  const {height}=useWindowSize();

  const {isDesktop,isMobile}=useResponsive();

  const compose_class=useMemo(()=>classnames(css.theme_content_block,className),[className]);

  const compose_effective_content_class=useMemo(()=>classnames({
    [css.effective_content_container_desktop]:isDesktop,
    [css.effective_content_container_mobile]:isMobile
  }),[isMobile,isDesktop]);

  const compose_style=useMemo(()=>Object.assign({},{
    minHeight:height,
    backgroundImage:`url(${bg_src})`
  },style),[bg_src,height,style]);

  return (
    <div style={compose_style} className={compose_class}>
      {no_block?null:(
        <div className={compose_effective_content_class}>
          <div className={css.effective_content}>
            <img src={icon} alt="" />
            <p>{title}</p>
            <div className={css.discription}>{discription}</div>
          </div>
        </div>
      )}
    </div>
)}


ThemeContentBlock.propTypes={


};
ThemeContentBlock.defaultProps={
  no_block:false
};