
export default {
  "关于STAR.VISION":{
    "模块简介1":{
      "标题":"R&D of AI Satellites·Remote Sensing Data Mining·Investment in Remote Sensing Constellation",
      "模块1":"R&D Experience of %%23%% Satellites",
      "模块2":"%%5%% Satellites in Orbit",
      "模块3":"Supply Key Components for %%40%%+ Satellites"
    },
    "分割模块1":{
      "副标题":"Extensive Cooperation will Bring Us Endless Possibilities for Space Exploration",
      "描述":"Unite for Mutual Interest, Sail Together to the Deep Space"
    },
    "模块简介2":{
      "模块1":"Potential Business in %%38%% countries",
      "模块2":"%%18%% Solutions for %%4%% industries",
      "模块3":"%%7%% partners in %%5%% countries",
    },
    "模块简介3":{
      "块级标题":"International Network",
      "地区名称1":{
        "标题":"Middle East",
        "描述":"Saudi Arabia,Oman,UAE,Qatar,Kuwait"
      },
      "地区名称2":{
        "标题":"Africa",
        "描述":"Egypt, Kenya, Ethiopia,South Africa, Tanzania, Mauritania, Morocco, Zimbabwe, Angola, Ghana, Zambia, Burkina Faso"
      },
      "地区名称3":{
        "标题":"Europe",
        "描述":"United Kingdom, France"
      },
      "地区名称4":{
        "标题":"South America",
        "描述":"Peru, Argentina, Chile, Dominica, Panama"
      },
      "地区名称5":{
        "标题":"South East Asia",
        "描述":"Cambodia,Laos, Singapore, Indonesia, Malaysia,Philippines, Pakistan, Sri Lanka, Nepal, Fiji"
      }
    },
    "分割模块2":{
      "口号":"MEANT TO BE STAR.VISION 必须远见",
      "联系方式":"CONTACT US",
      "地址":"ADDRESS：4th Floor, Building 2, Penghui Park, Yunqi Town, Xihu District, Hangzhou, Zhejiang",
      "邮箱":"EMAIL：SV@STAR.VISION"
    }
  }
};