/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
import BackgroundBlock from "@/components/BackgroundBlock";

import css from "./style.less";
import bg from "./assets/bg.jpg";

export default function ContentBlock4(){

  const {t}=useTranslation();

  const {isMobile,isDesktop}=useResponsive();

  const block_container_class=useMemo(()=>classnames({
    [css.block_container]:true,
    [css.block_container_mobile]:isMobile,
    [css.block_container_desktop]:isDesktop
  }),[isMobile,isDesktop]);

  const block_container_title_class=useMemo(()=>classnames({
    [css.block_container_title]:true
  }),[]);

  const block_container_detail_class=useMemo(()=>classnames({
    [css.block_container_detail]:true
  }),[]);

  return (
    <BackgroundBlock src={bg} className={css.content_block4}>
      <Grid container className={block_container_class}>
        {isMobile?(
          <Grid xs={12} item className={block_container_title_class}>
            {t("关于STAR.VISION.模块简介3.块级标题")}
          </Grid>
        ):null}
        <Grid xs={isMobile?12:6} item className={block_container_detail_class}>
          <div className={css.subject_block}>
            <strong>{t("关于STAR.VISION.模块简介3.地区名称1.标题")}</strong>
            <section>{t("关于STAR.VISION.模块简介3.地区名称1.描述")}</section>
          </div>
          <div className={css.subject_block}>
            <strong>{t("关于STAR.VISION.模块简介3.地区名称2.标题")}</strong>
            <section>{t("关于STAR.VISION.模块简介3.地区名称2.描述")}</section>
          </div>
          <div className={css.subject_block}>
            <strong>{t("关于STAR.VISION.模块简介3.地区名称3.标题")}</strong>
            <section>{t("关于STAR.VISION.模块简介3.地区名称3.描述")}</section>
          </div>
          <div className={css.subject_block}>
            <strong>{t("关于STAR.VISION.模块简介3.地区名称4.标题")}</strong>
            <section>{t("关于STAR.VISION.模块简介3.地区名称4.描述")}</section>
          </div>
          <div className={css.subject_block}>
            <strong>{t("关于STAR.VISION.模块简介3.地区名称5.标题")}</strong>
            <section>{t("关于STAR.VISION.模块简介3.地区名称5.描述")}</section>
          </div>
        </Grid>
        {isDesktop?(
          <Grid xs={6} item className={block_container_title_class}>
            {t("关于STAR.VISION.模块简介3.块级标题")}
          </Grid>
        ):null}
      </Grid>
    </BackgroundBlock>
)}


ContentBlock4.propTypes={


};
ContentBlock4.defaultProps={


};