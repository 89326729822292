import {getDocument} from "ssr-window";
import {useState,useEffect} from "react";

const document=getDocument()

function useProgressiveImg({lowQualitySrc, highQualitySrc}){
  const [src, setSrc] = useState(lowQualitySrc);
  
  useEffect(() => {
    setSrc(lowQualitySrc);
    const img = document.createElement("img");
    img.src = highQualitySrc;
    img.onload = () => {
      setSrc(highQualitySrc);
    };
  }, [lowQualitySrc, highQualitySrc]);

  return [src, src === lowQualitySrc];
}

export default useProgressiveImg;