/* eslint-disable react/prop-types */
import React from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";

import css from "./style.less";

export default function PageBanner({image_src}){
  return (
    <div style={{backgroundImage:`url(${image_src})`}} className={css.page_banner} />
)}


PageBanner.propTypes={


};
PageBanner.defaultProps={


};