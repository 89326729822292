
export default {
  "卫星遥感数据":{
    "主题部分":{
      "标题":"让历史可视化",
      "副标题":"遥感图像不止地图",
      "描述标题":"看见趋势 把握未来",
      "描述详情":"各类不同精度不同频段且快速更新的卫星遥感图像集，可以帮助您全天候多角度洞察目标区域，成为您做出重要决策的可靠依据。"
    },
    "模块简介1":{
      "标题":"AI智能算法",
      "副标题":"星上计算轻量化传输",
      "描述":JSON.stringify([
        "硬件资源虚拟化、系统软件平台化、应用软件多样化",
        "天基超算平台，开放系统架构，具备开源软件生态",
        "载荷即插即用，应用按需加载，星间星地自主协同",
        "星上直接完成图像预处理并提取有效信息， 避免传输大量冗余数据，大幅提升应用效率"
      ])
    },
    "模块简介2":{
      "标题":"交付灵活，精准高效",
      "副标题":"区域锁定 条件筛选",
      "描述":"可根据面积、精度、目标种类等灵活组合，按需购买"
    }
  }
};