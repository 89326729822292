/* eslint-disable react/prop-types */
import React from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";

import useWindowSize from "@/hooks/useWindowSize";
import css from "./style.less";

export default function ImageBanner({image_src,children}){
  const {height}=useWindowSize();
  return (
    <div style={{height,backgroundImage:`url(${image_src})`}} className={css.image_banner}>
      {children}
    </div>
)}


ImageBanner.propTypes={


};
ImageBanner.defaultProps={


};