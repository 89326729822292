
export default {
  "产业园区":{
    "主题部分":{
      "标题":"产业园区",
      "副标题":"INDUSTRIAL PARK"
    },
    "模块简介1":{
      "标题":"聚起来",
      "副标题":"产业聚集 垂直整合",
      "描述":"地卫二致力于卫星整星研制，将带动上下游产业聚集，推动从研发到设计、从生产到应用、从硬件到软件的全产业集群形成，拉动创新，实现产业升级，助力地方经济建设。"
    },
    "模块简介2":{
      "标题":"用起来",
      "副标题":"因地制宜 应用先行",
      "描述":"卫星网络是新一代信息技术的基础设施，卫星应用可达网络建设的20倍产值，产业园建设可因地制宜，从应用入手，小步快跑，形成特色。"
    }
  }
};