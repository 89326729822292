
export default {
  "轮播图部分":{
    "轮播图2":{
      "标题文字":"循此奇旅 以达天际",
      "按钮文字":"开始探索"
    },
    "轮播图3":{
      "标题文字":"卫星遥感数据",
      "描述文字":"让历史可视化",
      "按钮文字":"开始探索"
    },
    "轮播图4":{
      "标题文字":"下一代AI卫星平台",
      "描述文字":"从我们有的到你想要的",
      "按钮文字":"开始探索"
    },
  },
};
