
export default {
  "环境保护":{
    "主题部分":{
      "标题":"环境保护",
      "副标题":"",
      "描述":""
    },
    "模块描述1":{
      "标题":"动态监测助力可持续发展",
      "副标题":"防范不可逆破坏行为",
      "描述":"地卫二遥感星座对自然环境提供动态监测，确保自然资源得到合理利用，防止环境的污染和破坏，以求自然环境同人文环境、经济环境共同平衡可持续发展。"
    },
    "分割模块1":{
      "标题":"ECOLOGICAL MANAGEMENT",
      "副标题":"发挥天基优势，系统性地守护绿水青山。",
      "描述":"应对复杂的可持续性挑战"
    },
    "模块描述2":{
      "标题":"火眼金睛 无所遁形",
      "副标题":"高精度、及时反馈环境趋势",
      "描述":"多载荷多谱段协作，让环境污染无所遁形，将可持续发展和环境保护落到实处。"
    }
  }
};