import React from "react";
import {Routes,Route} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import BasicLayout from "@/layouts";

import WelcomePage from "@/pages/WelcomePage";
import AboutCompany from "@/pages/AboutCompany";

import WangJiangShan from "@/pages/Productions/pages/WangJiangShan";
import AISatellite from "@/pages/Productions/pages/AISatellite";
import Satellite from "@/pages/Productions/pages/Satellite";

import SmartOcean from "@/pages/SmartEarth/pages/SmartOcean";
import SmartCity from "@/pages/SmartEarth/pages/SmartCity";
import EmergencyManagement from "@/pages/SmartEarth/pages/EmergencyManagement";
import AgricultureForestry from "@/pages/SmartEarth/pages/AgricultureForestry";
import EnvironmentalProtection from "@/pages/SmartEarth/pages/EnvironmentalProtection";
import CarbonNeutral from "@/pages/SmartEarth/pages/CarbonNeutral";
import FinanceInsurance from "@/pages/SmartEarth/pages/FinanceInsurance";

import SpaceFlight from "@/pages/ExtendCooperation/SpaceFlight";
import IndustrialPark from "@/pages/ExtendCooperation/IndustrialPark";
import UnitedLaboratory from "@/pages/ExtendCooperation/UnitedLaboratory";

import "./global.less";

/**
 * react-router-dom v6的文档请查看这里
 * @see https://reactrouter.com/docs/en/v6/upgrading/v5
 * **/
export default ()=>{
  return (
    <Routes>
      <Route path="/" element={(<BasicLayout />)}>
        <Route path="/welcome" element={(<WelcomePage />)} />
        <Route path="/about_company" element={(<AboutCompany />)} />
        <Route path="/extend_cooperation">
          <Route path="/extend_cooperation/space_flight" element={(<SpaceFlight />)} />
          <Route path="/extend_cooperation/industrial_park" element={(<IndustrialPark />)} />
          <Route path="/extend_cooperation/united_laboratory" element={(<UnitedLaboratory />)} />
        </Route>
        <Route path="/smart_earth">
          <Route path="/smart_earth/smart_ocean" element={(<SmartOcean />)} />
          <Route path="/smart_earth/smart_city" element={(<SmartCity />)} />
          <Route path="/smart_earth/emergency_management" element={(<EmergencyManagement />)} />
          <Route path="/smart_earth/agriculture_forestry" element={(<AgricultureForestry />)} />
          <Route path="/smart_earth/environmental_protection" element={(<EnvironmentalProtection />)} />
          <Route path="/smart_earth/carbon_neutral" element={(<CarbonNeutral />)} />
          <Route path="/smart_earth/finance_insurance" element={(<FinanceInsurance />)} />
        </Route>
        <Route path="/productions">
          <Route path="/productions/wang_jiang_shan" element={(<WangJiangShan />)} />
          <Route path="/productions/ai_satellite" element={(<AISatellite />)} />
          <Route path="/productions/satellite" element={(<Satellite />)} />
        </Route>
      </Route>
    </Routes>
)};