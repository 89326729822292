/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
// import useWindowSize from "@/hooks/useWindowSize";
import css from "./style.less";

export default function BackgroundDiscription(props){
  const {bg_src,style,className,content_className,children}=props;

  // const {height}=useWindowSize();

  const {isMobile,isDesktop}=useResponsive();

  const compose_class=useMemo(()=>classnames(css.background_discription,className),[className]);

  const effective_content_container_class=useMemo(()=>classnames({
    [css.effective_content_container_desktop]:isDesktop,
    [css.effective_content_container_mobile]:isMobile,
  },content_className),[isMobile,isDesktop,content_className]);

  const compose_style=useMemo(()=>Object.assign({},{
    backgroundImage:`url(${bg_src})`
  },style),[bg_src,style]);

  return (
    <div style={compose_style} className={compose_class}>
      <div className={effective_content_container_class}>
        <div className={css.effective_content}>
          {children}
        </div>
      </div>
    </div>
)}


BackgroundDiscription.propTypes={


};
BackgroundDiscription.defaultProps={


};