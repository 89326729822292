
export default {
  "下一代AI卫星平台":{
    "主题部分":{
      "标题":"即刻转变 从我们有的到你想要的",
      "副标题":"下一代AI卫星平台",
      "描述标题":"",
      "描述详情":""
    },
    "模块简介1":{
      "标题":"最强AI能力",
      "描述":"媲美地面自动驾驶的同等算力，支持F-R-CNN及YOLO等先进算法框架，内置STAR.VISION改良AI算法，在轨自主学习。"
    },
    "模块简介2":{
      "标题":"高速安全OTA",
      "描述":"算法和应用高速安全更新，支持上行不低于20Mbps的在轨更新。"
    },
    "模块简介3":{
      "标题":"商业操作系统",
      "描述":"第一次实现商业操作系统在轨部署支持星地云一体化互联及算力共享"
    }
  }
};