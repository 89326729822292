/* eslint-disable react/prop-types */
import React from "react";
import {NavLink} from "react-router-dom";
// import classnames from "classnames";
// import propTypes from "prop-types";

import FlexRow from "@/components/FlexRow";
import css from "./style.less";

export default function BannerGhostButton({link,href,children}){
  if(href){
    return (
      <a style={{display:"block"}} href={href}>
        <FlexRow justify="center" className={css.banner_ghost_button}>
          {children}
        </FlexRow>
      </a>
  )}
  if(link){
    return (
      <NavLink style={{display:"block"}} to={link}>
        <FlexRow justify="center" className={css.banner_ghost_button}>
          {children}
        </FlexRow>
      </NavLink>
  )}
  return null;
}


BannerGhostButton.propTypes={


};
BannerGhostButton.defaultProps={


};