
export default {
  "金融与保险":{
    "主题部分":{
      "标题":"FINANCE AND INSURANCE",
      "副标题":"",
      "描述":""
    },
    "模块描述1":{
      "标题":"Underwriting of fragmented plots, crop types planted?",
      "副标题":"Accurate verification and effective management",
      "描述":"Insurance underwriting and loss evaluation, avoiding hedging and insurance fraud to the greatest extent, explore the spatial and temporal attributes of insurance data, and accurately develop and manage insurance products"
    },
    "分割模块1":{
      "标题":"FINANCE AND INSURANCE MANAGEMENT",
      "副标题":"Global resource utilization trend reflects financial operation",
      "描述":"Understanding of resource utilization status can strengthen the insight to trends and control of risk"
    },
    "模块描述2":{
      "标题":"Statistics of global crudeoil stocks, various futures data?",
      "副标题":"Comprehensive statistics,objective and efficient",
      "描述":"Avoid the disadvantages of complex, unclear, and delayed statistical data sources. Through intelligent analysis and collation of remote sensing imagery, comprehensive, objective, and near real-time data can be obtained from the perspective of satellites"
    }
  }
};