/* eslint-disable react/require-default-props */
import propTypes from "prop-types";
import classnames from "classnames";
import React, { useMemo } from "react";

import css from "./style.less";
/**
 * > 水平模式的弹性布局
 * 
 * - flex上下布局模式
 * - 元素左右排列
 * - 对齐模式为space-between
 * 
 * ### 使用方式
 * ```javascript
 * import FlexRow from "yunxi-taro/layout/FlexRow";
 * ```
 * */

const FlexRow=React.forwardRef((props,ref)=>{

  const { flex, align, wrap, justify, ...otherProps } = props;

  const { style, className, children, ...restProps } = otherProps;

  const comptedStyle = useMemo(() => {
    return Object.assign({}, {
      flex,
      flexWrap: wrap,
      alignItems: align,
      justifyContent: justify,
    }, style);
  }, [wrap, flex, align, justify, style]);

  const computedClass = useMemo(() => {
    return classnames(css.flex_row, className)
  }, [className]);

  return (
    <div ref={ref} style={comptedStyle} className={computedClass} {...restProps}>
      {children}
    </div>
)});

FlexRow.displayName="FlexRow";

FlexRow.propTypes = {
  /**
   * flex 数值
   **/
  flex: propTypes.number,
  /**
   * 是否换行
   **/
  wrap: propTypes.oneOf(["no-wrap", "wrap"]),
  /**
   * 垂直方向对齐模式
   **/
  align: propTypes.string,
  /**
   * 水平方向对齐模式
   **/
  justify: propTypes.string,
  /**
   * className
   **/
  className: propTypes.string
};

FlexRow.defaultProps = {
  className:"",
  wrap: "no-wrap",
  align: "center",
  justify: "space-between"
};

export default FlexRow;
