
export default {
  "应急管理":{
    "主题部分":{
      "标题":"EMERGENCY MANAGEMENT",
      "副标题":"",
      "描述":""
    },
    "模块描述1":{
      "标题":"Finance and Insurance",
      "副标题":"Integration of space and earth information",
      "描述":"Response to emergencies requires timely and accurate spatial geographic information to assist decision-making. The satellite network is an essential communication guarantee for serious disaster interruption."
    },
    "分割模块1":{
      "标题":"EMERGENCY MANAGEMENT",
      "副标题":"ACT IN ADVANCE, PROTECT YOUR HOME PROPERLY",
      "描述":"Touch city pulse and react promptly"
    },
    "模块描述2":{
      "标题":"Post-disaster assessment",
      "副标题":"Event review Accurate reconstruction",
      "描述":"What's the problem? What’s the loopholes? What can be improved? Post disaster recovery and reconstruction are as important as emergency response. Decision makers need to be ready for the next challenge."
    }
  }
};