/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";

import css from "./style.less";
import footerimage from "./assets/footerimg.jpg";

export default function MobileFooter(){
  const {t}=useTranslation();
  return (
    <div style={{backgroundImage:`url(${footerimage})`}} className={css.copy_right_block_container}>
      <div className={css.copy_right_block}>
        <span>{t("页脚部分.详情")}</span>
        <a style={{color:"white"}} href="#">{t("页脚部分.隐私政策")}</a>  |
        <a style={{color:"white"}} href="#">{t("页脚部分.使用条款")}</a>
      </div>
    </div>
)}


MobileFooter.propTypes={


};
MobileFooter.defaultProps={


};