
export default {
  "金融与保险":{
    "主题部分":{
      "标题":"金融与保险",
      "副标题":"",
      "描述":""
    },
    "模块描述1":{
      "标题":"以确切数据降低风险",
      "副标题":"精确核定 有效管理",
      "描述":"保险核保定损，最大限度避免套保，骗保行为，发掘保险数据的空间，时间属性，精准开发管理保险产品。"
    },
    "分割模块1":{
      "标题":"FINANCE AND INSURANCE",
      "副标题":"当全球资源动向触及金融脉搏",
      "描述":"了解真实资源现状才能看清趋势，防范风险"
    },
    "模块描述2":{
      "标题":"全球原油存量，各类期货数据",
      "副标题":"全面统计 客观高效",
      "描述":"避免统计数据源复杂、不清晰、滞后延迟的弊端，通过对遥感图像数据的智能分析和整理，从卫星的视角得到全面的、客观的、接近实时的数据。"
    }
  }

};