/* eslint-disable react/prop-types */
import classnames from "classnames";
import {useNavigate} from "react-router-dom";
import React,{useMemo,useCallback} from "react";
// import propTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';

import FlexRow from "@/components/FlexRow";
import LanguageSwitch from "@/layouts/elements/LanguageSwitch";

import logo from "@/layouts/assest/logo.png";
import css from "./style.less";

export default function TopBlock(props){

  const navigate=useNavigate();

  const {toggle_state,onToggleState}=props;

  const mobile_header_class=useMemo(()=>classnames({
    [css.mobile_header]:true,
    [css.mobile_header_open]:toggle_state
  }),[toggle_state]);

  const handleClick=useCallback(()=>navigate("/welcome"),[navigate]);

  return (
    <FlexRow className={mobile_header_class}>
      <FlexRow>
        <img className={css.logo_icon} src={logo} alt="" onClick={handleClick} />
      </FlexRow>
      <FlexRow className={css.toggle_block}>
        <FlexRow className={css.select_language}>
          <LanguageSwitch type="en" language="ENGLISH" />
          <LanguageSwitch type="zh" language="中文(简体)" />
        </FlexRow>
        <FlexRow className={css.toggle_icon_container} onClick={onToggleState}>
          {toggle_state?(<CloseIcon />):(<MenuIcon />)}
        </FlexRow>
      </FlexRow>
    </FlexRow>
)}


TopBlock.propTypes={


};
TopBlock.defaultProps={


};