/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";

import css from "./style.less";

import Model3dIframe from "./elements/Model3dIframe";
import ImageViewBlock from "./elements/ImageViewBlock";
import DetailDividerBlock from "./layouts/DetailDividerBlock";

export default function SatellitePerformanceParameter(props){
  const {name,view_block,model_3d,performance}=props;

  const {t}=useTranslation();

  return (
    <div className={css.satellite_performance_parameter}>
      <div className={css.label}>
        <p>
          <strong>WONDERJOURNEY</strong>
          <span>SERIES</span>
        </p>
        <p>
          <strong>{t("望江山一代机.望江山")}</strong>
          <span>{t("望江山一代机.整星系列")}</span>
        </p>
        <div className={css.label_divider} />
      </div>
      <div className={css.satellite_name}>{name}</div>
      <DetailDividerBlock>
        {model_3d?(<Model3dIframe model_3d={model_3d} />):null}
        {view_block?(<ImageViewBlock view_block={view_block} />):null}
      </DetailDividerBlock>
      <ul className={css.performance_detail}>
        {performance.map((performance_detail)=>{
          return (
          <li key={performance_detail}>
            {performance_detail}
          </li>
        )})}
      </ul>
    </div>
)}


SatellitePerformanceParameter.propTypes={


};
SatellitePerformanceParameter.defaultProps={
  
};