/* eslint-disable react/prop-types */
import React from "react";
import {Outlet} from "react-router-dom";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

import useResponsive from "@/hooks/useResponsive";

import DesktopHeader from "./components/DesktopHeader";
import MobileFooter from "./components/MobileFooter";
import DesktopFooter from "./components/DesktopFooter";
import MobileHeader from "./components/MobileHeader";

import navigation from "./configs/navigation";
import footer_content from "./configs/footer_content";

export default function BasicLayout(){

  const {isMobile,isDesktop}=useResponsive();

  return (
    <CustomScroll allowOuterScroll>
      {isMobile?(
        <MobileHeader navigation={navigation} />
      ):null}
      {isDesktop?(
        <DesktopHeader navigation={navigation} />
      ):null}
      <Outlet />
      {isMobile?(
        <MobileFooter footer_content={footer_content} />
      ):null}
      {isDesktop?(
        <DesktopFooter footer_content={footer_content} />
      ):null}
    </CustomScroll>
)}


BasicLayout.propTypes={


};
BasicLayout.defaultProps={


};