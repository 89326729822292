/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
import useWindowSize from "@/hooks/useWindowSize";
import css from "./style.less";

export default function SatelliteDividerBlock({color,bg_src,title,discription,style,className}){
  
  const {height}=useWindowSize();

  const {isMobile,isDesktop}=useResponsive();

  const compose_class=useMemo(()=>classnames(css.satellite_divider_block,className),[className]);

  const effective_content_container_class=useMemo(()=>classnames({
    [css.effective_content_container_desktop]:isDesktop,
    [css.effective_content_container_mobile]:isMobile
  }),[isMobile,isDesktop]);

  const compose_style=useMemo(()=>Object.assign({},{
    color,
    minHeight:height*0.8,
    backgroundImage:`url(${bg_src})`
  },style),[color,bg_src,height,style]);

  return (
    <div style={compose_style} className={compose_class}>
      <div className={effective_content_container_class}>
        <div className={css.effective_content}>
          <div>{title}</div>
          <p>{discription}</p>
        </div>
      </div>
    </div>
)}


SatelliteDividerBlock.propTypes={


};
SatelliteDividerBlock.defaultProps={


};