/* eslint-disable react/prop-types */
import React from "react";
import QueueAnim from "rc-queue-anim";
// import classnames from "classnames";
// import propTypes from "prop-types";

import FlexCol from "@/components/FlexCol";

import css from "./style.less";
import {NavigateContext} from "../../elements/NavigateContext";
import LevelOneNavigate from "../../elements/LevelOneNavigate";
import LevelTwoNavigate from "../../elements/LevelTwoNavigate";

export default function MobileNavigate({toggle_state,navigation}){
  return (
    <QueueAnim type="top" style={{width:"100%",height:"100%"}}>
      {toggle_state?(
        <FlexCol key="a" flex={1} justify="start" className={css.mobile_navigate}>
          {navigation.map(({name,link,sub_navigation},index)=>{
            return (
              <NavigateContext key={name}>
                <LevelOneNavigate name={name} link={link} canExpand={sub_navigation} isOdd={index%2===1} />
                {sub_navigation?(
                  <LevelTwoNavigate sub_navigation={sub_navigation} />
                ):null}
              </NavigateContext>
          )})}
        </FlexCol>
      ):null}
    </QueueAnim>
)}


MobileNavigate.propTypes={


};
MobileNavigate.defaultProps={


};