
export default {
  "智慧海洋":{
    "主题部分":{
      "标题":"SMART OCEAN",
      "副标题":"",
      "描述":""
    },
    "模块描述1":{
      "标题":"WHAT'S HAPPENING IN OCEAN",
      "副标题":"Information awareness and interconnection",
      "描述":"Awareness of marine geography, marine biology, marine chemistry, marine transportation, marine environment and marine geology, open and compatible marine big data cloudplatform, collaborative interaction of comprehensive marine information;"
    },
    "分割模块1":{
      "标题":"SMART OCEAN",
      "副标题":"Treat ocean as a chessboard, dealing the moves effortless",
      "描述":"Exploit ocean resources, strengthen international cooperation"
    },
    "模块描述2":{
      "标题":"Focus on marine activities and economic value",
      "副标题":"Smart service, share the ocean",
      "描述":"Real-time monitoring, providing diversified service information for marine activities, disaster prevention and mitigation,and marine economic development, and improving marine management capabilities;"
    }
  }
};