/* eslint-disable react/prop-types */
import React,{useState,useContext} from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";

// import css from "./style.scss";
// import css from "./style.less";

const Context=React.createContext();

export function useNavigateContext(){
  const consumer=useContext(Context);
  return consumer;
}

export function NavigateContext({children}){

  const [expand_status,set_expand_status]=useState(false);

  return (
    <Context.Provider value={{expand_status,set_expand_status}}>
      {children}
    </Context.Provider>
)}


NavigateContext.propTypes={


};
NavigateContext.defaultProps={


};