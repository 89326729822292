/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";

// import classnames from "classnames";
// import propTypes from "prop-types";

import useProgressiveImg from "@/hooks/useProgressiveImg";
import BackgroundBlock from "@/components/BackgroundBlock";
import ThemeContentBlock from "@/components/ThemeContentBlock";
import ContentImageBlock from "@/components/ContentImageBlock";
import PreviewContentBlock from "@/components/PreviewContentBlock";
// import BackgroundDiscription from "@/components/BackgroundDiscription";


import css from "./style.less";
import bg_src from "./assest/bg_src.jpg";
import bg_src_low from "./assest/bg_src.low.jpg";
import icon from "./assest/spaceflightimg1.png";
import block1_bg_src from "./assest/block1_bg_src.png";
import block2_bg_src from "./assest/block2_bg_src.jpg";
import block3_bg_src from "./assest/block3_bg_src.jpg";

export default function UnitedLaboratory(){
  const {t}=useTranslation();
  const [current_about_banner]=useProgressiveImg({lowQualitySrc:bg_src_low,highQualitySrc:bg_src});
  return (
    <div>
      <ThemeContentBlock
        icon={icon}
        bg_src={current_about_banner}
        title={t("联合实验室.主题部分.标题")}
        discription={t("联合实验室.主题部分.副标题")}
      />
      <PreviewContentBlock
        color="white"
        bg_src={block1_bg_src}
        title={t("联合实验室.模块简介1.标题")}
        sub_title={t("联合实验室.模块简介1.副标题")}
        discription={t("联合实验室.模块简介1.描述")}
      />
      <BackgroundBlock>
        <ContentImageBlock
          image_src={block2_bg_src}
          className={css.content_image_block}
        />
      </BackgroundBlock>
      <PreviewContentBlock
        color="white"
        bg_src={block3_bg_src}
        title={t("联合实验室.模块简介2.标题")}
        sub_title={t("联合实验室.模块简介2.副标题")}
        discription={t("联合实验室.模块简介2.描述")}
      />
    </div>
)}


UnitedLaboratory.propTypes={


};
UnitedLaboratory.defaultProps={


};