
export default {
  "轮播图部分":{
    "轮播图2":{
      "标题文字":"AD ASTRA PER ASPERA",
      "按钮文字":"EXPLORE NOW"
    },
    "轮播图3":{
      "标题文字":"REMOTE SENSING DATA",
      "描述文字":"",
      "按钮文字":"EXPLORE NOW"
    },
    "轮播图4":{
      "标题文字":"NEXT GENERATION AI SATELLITE PLATFORM",
      "描述文字":"",
      "按钮文字":"EXPLORE NOW"
    }
  },
};