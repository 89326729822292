/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
// import propTypes from "prop-types";

// import useWindowSize from "@/hooks/useWindowSize";
import css from "./style.less";

export default function BackgroundBlock({src,style,className,children}){
  // const {height}=useWindowSize();
  const compose_class=useMemo(()=>classnames(css.background_block,className),[className]);
  const compose_style=useMemo(()=>Object.assign({},{
    // height:height*0.4,
    backgroundImage:`url(${src})`
  },style),[src,style]);
  return (
    <div style={compose_style} className={compose_class}>
      {children}
    </div>
)}


BackgroundBlock.propTypes={


};
BackgroundBlock.defaultProps={


};