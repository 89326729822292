/* eslint-disable react/prop-types */
import React from "react";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

// import classnames from "classnames";
// import propTypes from "prop-types";

import useProgressiveImg from "@/hooks/useProgressiveImg";
import ThemeContentBlock from "@/components/ThemeContentBlock";
import PreviewContentBlock from "@/components/PreviewContentBlock";
import DivisionContentBlock from "@/components/DivisionContentBlock";
import BackgroundDiscription from "@/components/BackgroundDiscription";

import DiscriptionLayoutBlock from "@/pages/SmartEarth/components/DiscriptionLayoutBlock";

import bg_src from "./assest/bg_src.jpg";
import bg_src_low from "./assest/bg_src.low.jpg";
import icon from "./assest/spaceflightimg1.png";
import block1_bg_src from "./assest/block1_bg_src.jpg";
import block2_bg_src from "./assest/block2_bg_src.jpg";
import block3_bg_src from "./assest/block3_bg_src.jpg";

const CustmerImg=styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function SmartCity(){
  const {t}=useTranslation();
  const [current_about_banner]=useProgressiveImg({lowQualitySrc:bg_src_low,highQualitySrc:bg_src});
  return (
    <div>
      <ThemeContentBlock icon={icon} bg_src={current_about_banner} title={t("智慧城市.主题部分.标题")} discription={t("智慧城市.主题部分.副标题")} />
      <BackgroundDiscription>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <DiscriptionLayoutBlock
              color="black"
              title={t("智慧城市.模块描述1.标题")}
              sub_title={t("智慧城市.模块描述1.副标题")}
              discription={t("智慧城市.模块描述1.描述")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustmerImg src={block1_bg_src} />
          </Grid>
        </Grid>
      </BackgroundDiscription>
      <DivisionContentBlock
        bg_src={block2_bg_src}
        title={t("智慧城市.分割模块1.标题")}
        sub_title={t("智慧城市.分割模块1.副标题")}
        discription={t("智慧城市.分割模块1.描述")}
      />
      <PreviewContentBlock
        color="black"
        bg_src={block3_bg_src}
        title={t("智慧城市.模块描述2.标题")}
        sub_title={t("智慧城市.模块描述2.副标题")}
        discription={t("智慧城市.模块描述2.描述")}
      />
    </div>
)}


SmartCity.propTypes={


};
SmartCity.defaultProps={


};