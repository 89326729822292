/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
import FlexRow from "@/components/FlexRow";
import FlexCol from "@/components/FlexCol";
import css from "./style.less";

export default function HorizontalBlock({title,discription,children}){
  
  const {isMobile,isDesktop}=useResponsive();

  const horizontal_block_class=useMemo(()=>classnames({
    [css.horizontal_block]:true,
    [css.horizontal_block_mobile]:isMobile,
    [css.horizontal_block_desktop]:isDesktop,
  }),[isMobile,isDesktop]);

  const horizontal_block_left_class=useMemo(()=>classnames({
    [css.horizontal_block_left]:true,
    [css.horizontal_block_left_mobile]:isMobile,
    [css.horizontal_block_left_desktop]:isDesktop,
  }),[isMobile,isDesktop]);

  return (
    <FlexRow className={horizontal_block_class}>
      <FlexCol className={horizontal_block_left_class}>
        <FlexRow className={css.title}>{title}</FlexRow>
        <FlexRow className={css.discription}>{discription}</FlexRow>
      </FlexCol>
      <FlexRow>{children}</FlexRow>
    </FlexRow>
)}


HorizontalBlock.propTypes={


};
HorizontalBlock.defaultProps={


};