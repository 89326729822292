import {getWindow} from "ssr-window";
import {useState,useEffect, useCallback} from "react";

const window=getWindow();

export default function useWindowSize(){
  const [window_size,set_window_size]=useState({width:0,height:0});
  
  const handelCallback=useCallback(()=>{
    set_window_size({width:window.innerWidth,height:window.innerHeight});
  },[]);

  useEffect(()=>{
    if(window){
      set_window_size({width:window.innerWidth,height:window.innerHeight});
      window.addEventListener("resize",handelCallback,true);
      return ()=>window.removeEventListener("resize",handelCallback,true);
    }
  },[handelCallback]);

  return window_size;
}