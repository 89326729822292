/* eslint-disable react/prop-types */
import React from "react";
import QueueAnim from "rc-queue-anim";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";

import css from "./style.less";

export default function LevelTwoButton({show,onTaggleShow,onTaggleHidden,discription,navigation}){
  const {t}=useTranslation();
  if(show){
    return (
      <QueueAnim type="top">
        <div key="a" className={css.subject_navigation} onMouseEnter={onTaggleShow} onMouseLeave={onTaggleHidden}>
          <div className={css.discription}>
            {t(discription)}
          </div>
          {navigation.map(({name,link,discription})=>{
            return (
              <div key={name} className={css.nav_link}>
                <NavLink to={link} className={css.nav_link_block}>
                  {t(name)}
                </NavLink>
                <div className={css.nav_link_discription}>
                  {t(discription)}
                </div>
              </div>
          )})}
        </div>
      </QueueAnim>
  )}
  return null;
}


LevelTwoButton.propTypes={


};
LevelTwoButton.defaultProps={
  navigation:[],
  onTaggleShow(){},
  onTaggleHidden(){}
};