/* eslint-disable react/prop-types */
import React,{useCallback} from "react";
import {useLocation} from "react-router-dom";
// import classnames from "classnames";
// import propTypes from "prop-types";

import css from "./style.less";

export default function LanguageSwitch({type,language}){

  const {pathname}=useLocation();
  
  const handleClick=useCallback(()=>{
    const navigate_path=`${type}${pathname}`;
    window.location.pathname=navigate_path;
  },[type,pathname]);
  
  return (
    <div className={css.language_switch} onClick={handleClick}>
      {language}
    </div>
)}


LanguageSwitch.propTypes={


};
LanguageSwitch.defaultProps={


};