/* eslint-disable react/prop-types */
import React from "react";
import QueueAnim from "rc-queue-anim";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
// import propTypes from "prop-types";

import FlexCol from "@/components/FlexCol";
import {useNavigateContext} from "../NavigateContext";
import css from "./style.less";

export default function LevelTwoNavigate({sub_navigation}){
  const {t}=useTranslation();
  const {expand_status,set_expand_status}=useNavigateContext();

  return (
    <QueueAnim type="top" duration={300} style={{overflow:"hidden"}}>
      {expand_status?(
        <FlexCol key="a" className={css.sub_navigation_container}>
          {sub_navigation.map(({name,link,href})=>{
            if(href){
              return (
                <a key={name} href={href} className={css.sub_navigation} onClick={()=>set_expand_status(false)}>{t(name)}</a>
            )}
            if(link){
              return (
                <NavLink key={name} to={link} className={css.sub_navigation} onClick={()=>set_expand_status(false)}>
                  {t(name)}
                </NavLink>
            )}
            return null;
          })}
        </FlexCol>
      ):null}
    </QueueAnim>
)}


LevelTwoNavigate.propTypes={


};
LevelTwoNavigate.defaultProps={
  sub_navigation:[]
};