
export default {
  "智慧城市":{
    "主题部分":{
      "标题":"SMART CITY",
      "副标题":"",
      "描述":""
    },
    "模块描述1":{
      "标题":"What happened in your city ten minutes ago?",
      "副标题":"City brain, Intelligent awareness from Space",
      "描述":"Howmany boats are there in the West Lake scenic area? How many cars are thereon the roads? How is the rescue progress of safety accidents? How much area was destroyed by the fire? Where does the pollution of rivers come from? What area has the flood reached? Both intelligent transportation and grid management are inseparable from the city level integrated network of space and STAR.VISION earth satellite planned constellation includes 300 remote sensing satellites, which can revisit any point in the world within 10 minutes."
    },
    "分割模块1":{
      "标题":"SMART CITY",
      "副标题":"Vision defines mindset, AI empowers Smart City",
      "描述":"Deep feeling of city pulse, prompt reaction"
    },
    "模块描述2":{
      "标题":"What will happen in city in ten minutes?",
      "副标题":"Devise strategies Planfor the future",
      "描述":"Is the scenic spot you are going to enter a state of congestion? Can the school sports meeting and star concert be successfully opened? Which area is the escape vehicle likely to enter? Is it necessary to evacuate urban waterlogged areas? How many planes are expected to take off from Xiaoshan Airport today? Rich historical data and time-sensitive geographic information, coupled with the exclusive AI algorithm of STAR.VISION, let city managers plan the future effectively."
    }
  }
};