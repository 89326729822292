
export default {
  "卫星遥感数据":{
    "主题部分":{
      "标题":"MAKE HISTORY VISIBLE",
      "副标题":"More than a map",
      "描述标题":"Penetrate the appearance, Grasp the future",
      "描述详情":"A variety of fast updated satellite remote sensing imagery sets with different precision and frequency bands can help to gain all-weather insight into the target area from multiple angles and become a reliable basis to make critical decisions"
    },
    "模块简介1":{
      "标题":"Advanced AI algorithm",
      "副标题":"In-Orbit processing enables lite transmission",
      "描述":JSON.stringify([
        "Virtualization of hardware resources, platform of system software, and diversification of application software",
        "Take the space-based supercomputing platform as the key capability, adopt open system architecture, and have opensource software ecosystem",
        "Plug and play of payloads, on-demand loading of software application, autonomous coordination between satellites and ground segments",
        "Complete image preprocessing and information extraction on the satellite, avoiding the transmission waster of massive redundant data, and improving application efficiency significantly"
      ])
    },
    "模块简介2":{
      "标题":"On-demand delivery make your work efficient",
      "副标题":"Filter by user-defined criteria*",
      "描述":"Purchase on demand, flexible combination according to area, precision, object type, etc."
    }
  }
};