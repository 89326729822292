/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";
import useResponsive from "@/hooks/useResponsive";
import useProgressiveImg from "@/hooks/useProgressiveImg";

import ImageBanner from "../../elements/ImageBanner";
import BannerGhostButton from "../../elements/BannerGhostButton";

import VerticalBlock from "../../layouts/VerticalBlock";

import css from "./style.less";
import banner1 from "../../assets/banner1_1.jpg";
import banner1_low from "../../assets/banner1_1.low.jpg";
import banner1_mobile from "../../assets/banner1_1_mobile.jpg";

export default function Banner1(){
  const {isMobile,isDesktop}=useResponsive();
  
  const [current_about_banner]=useProgressiveImg({
    lowQualitySrc:banner1_low,
    highQualitySrc:banner1
  });

  const computed_images=useMemo(()=>{
    if(isMobile){
      return banner1_mobile;
    }
    if(isDesktop){
      return current_about_banner;
    }
    return current_about_banner;
  },[current_about_banner,isMobile,isDesktop]);

  return (
    <ImageBanner className={css.banner1} image_src={computed_images}>
      {/*<VerticalBlock>
        <BannerGhostButton href="http://hv.star.vision/">
          Join Our Space Journey
        </BannerGhostButton>
  </VerticalBlock>*/}
    </ImageBanner>
)}


Banner1.propTypes={


};
Banner1.defaultProps={


};