/* eslint-disable react/prop-types */
import React from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";

import css from "./style.less";

export default function ImageViewBlock(props){
  const {view_block}=props;
  return (
    <div className={css.view_block}>
      <img src={view_block} alt="" />
    </div>
)}


ImageViewBlock.propTypes={


};
ImageViewBlock.defaultProps={


};