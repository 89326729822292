/* eslint-disable react/require-default-props */
import propTypes from "prop-types";
import classnames from "classnames";
import React, { useMemo } from "react";

import css from "./style.less";

/**
 * > 垂直模式的弹性布局
 * 
 * - flex上下布局模式
 * - 元素自上而下排列
 * - 对齐模式为space-between
 * 
 * ### 使用方式
 * ```javascript
 * import FlexCol from "yunxi-taro/layout/FlexCol";
 * ```
 */

const FlexCol=React.forwardRef((props,ref)=>{

  const { wrap ,flex, align, justify, ...otherProps } = props;

  const { style, className, children, ...restProps } = otherProps;

  const computedStyle = useMemo(() => {
    return Object.assign({}, {
      flex,
      flexWrap: wrap,
      alignItems: align,
      justifyContent: justify,
    }, style);
  }, [flex, wrap ,align, justify, style]);

  const computedClass = useMemo(() => {
    return classnames(css.flex_col, className);
  }, [className]);

  return (
    <div ref={ref} style={computedStyle} className={computedClass} {...restProps}>
      {children}
    </div>
)});

FlexCol.displayName="FlexCol";

FlexCol.propTypes = {
  /**
   * flex 数值
   * */
  flex: propTypes.number,
  /**
   * 是否换行
   **/
  wrap: propTypes.oneOf(["no-wrap", "wrap"]),
  /**
   * 垂直方向对齐模式
   * */
  align: propTypes.string,
  /**
   * 水平方向对齐模式
   * */
  justify: propTypes.string,
  /**
   * className
   * */
  className: propTypes.string
};

FlexCol.defaultProps = {
  align:"",
  className:"",
  justify:"space-between"
};


export default FlexCol;