/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import {useTranslation} from "react-i18next";

// import classnames from "classnames";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
import useProgressiveImg from "@/hooks/useProgressiveImg";
import ThemeContentBlock from "@/components/ThemeContentBlock";
import PreviewContentBlock from "@/components/PreviewContentBlock";
import DivisionContentBlock from "@/components/DivisionContentBlock";

// import css from "./style.scss";
// import css from "./style.less";
import bg_src from "./assest/bg_src.png";
import bg_src_low from "./assest/bg_src.low.png";

import bg_src_mobile from "./assest/bg_src_mobile.png";
import bg_src_mobile_low from "./assest/bg_src_mobile.low.png";

import icon from "./assest/spaceflightimg1.png";
import block1_bg_src from "./assest/block1_bg_src.jpg";
import block2_bg_src from "./assest/block2_bg_src.jpg";
import block3_bg_src from "./assest/block3_bg_src.jpg";


export default function SmartOcean(){
  const {t}=useTranslation();
  const {isMobile,isDesktop}=useResponsive();

  const [current_desktop_banner]=useProgressiveImg({lowQualitySrc:bg_src_low,highQualitySrc:bg_src});
  const [current_mobile_banner]=useProgressiveImg({lowQualitySrc:bg_src_mobile_low,highQualitySrc:bg_src_mobile});

  const current_render_bg=useMemo(()=>{
    if(isMobile){
      return current_mobile_banner;
    }
    if(isDesktop){
      return current_desktop_banner;
    }
    return current_desktop_banner;
  },[isMobile,isDesktop,current_mobile_banner,current_desktop_banner]);

  return (
    <div>
      <ThemeContentBlock
        icon={icon}
        bg_src={current_render_bg} 
        title={t("智慧海洋.主题部分.标题")}
        discription={t("智慧海洋.主题部分.副标题")}
      />
      <PreviewContentBlock
        color="black"
        bg_src={block1_bg_src}
        title={t("智慧海洋.模块描述1.标题")}
        sub_title={t("智慧海洋.模块描述1.副标题")}
        discription={t("智慧海洋.模块描述1.描述")}
      />
      <DivisionContentBlock
        bg_src={block2_bg_src}
        title={t("智慧海洋.分割模块1.标题")}
        sub_title={t("智慧海洋.分割模块1.副标题")}
        discription={t("智慧海洋.分割模块1.描述")}
      />
      <PreviewContentBlock
        color="white"
        bg_src={block3_bg_src}
        title={t("智慧海洋.模块描述2.标题")}
        sub_title={t("智慧海洋.模块描述2.副标题")}
        discription={t("智慧海洋.模块描述2.描述")}
      />
    </div>
)}


SmartOcean.propTypes={


};
SmartOcean.defaultProps={


};