/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";
import useResponsive from "@/hooks/useResponsive";
import useProgressiveImg from "@/hooks/useProgressiveImg";

import ImageBanner from "../../elements/ImageBanner";
import BannerGhostButton from "../../elements/BannerGhostButton";

import VerticalBlock from "../../layouts/VerticalBlock";

import css from "./style.less";
import banner5 from "../../assets/banner5.jpg";
import banner5_low from "../../assets/banner5low.jpg";
import banner5_mobile from "../../assets/banner5_mobile.jpg";

export default function Banner1(){
  const {isMobile,isDesktop}=useResponsive();
  
  const [current_about_banner]=useProgressiveImg({
    lowQualitySrc:banner5_low,
    highQualitySrc:banner5
  });

  const computed_images=useMemo(()=>{
    if(isMobile){
      return banner5_mobile;
    }
    if(isDesktop){
      return current_about_banner;
    }
    return current_about_banner;

  },[current_about_banner,isMobile,isDesktop]);

  return (
    <ImageBanner className={css.banner1} image_src={computed_images}>
      {/*<VerticalBlock>
        <BannerGhostButton href="http://hv.star.vision/">
          Join Our Space Journey
        </BannerGhostButton>
  </VerticalBlock>*/}
    </ImageBanner>
)}


Banner1.propTypes={


};
Banner1.defaultProps={


};