/* eslint-disable react/prop-types */
import classnames from "classnames";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React,{useMemo,useCallback} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// import propTypes from "prop-types";

import FlexRow from "@/components/FlexRow";
import {useNavigateContext} from "../NavigateContext";
import css from "./style.less";

export default function LevelOneNavigate({name,link,isOdd,canExpand}){

  const {t}=useTranslation();

  const {expand_status,set_expand_status}=useNavigateContext();

  const level_one_navigate_class=useMemo(()=>classnames({
    [css.level_one_navigate]:true,
    [css.level_one_navigate_odd]:isOdd
  }),[isOdd]);

  const handleClick=useCallback(()=>{
    set_expand_status(!expand_status)
  },[expand_status,set_expand_status]);

  return (
    <FlexRow className={level_one_navigate_class}>
      <FlexRow className={css.sub_link} onClick={handleClick}>
        {link?(
          <NavLink className={css.link_content} to={link}>{t(name)}</NavLink>
        ):(
          <div className={css.link_content}>{t(name)}</div>
        )}
        {canExpand&&(expand_status?(<ExpandLessIcon />):(<ExpandMoreIcon />))}
      </FlexRow>
    </FlexRow>
)}


LevelOneNavigate.propTypes={


};
LevelOneNavigate.defaultProps={


};