import WelcomePage from "@/pages/WelcomePage/locales/zh";
import AboutCompany from "@/pages/AboutCompany/locales/zh";

import Satellite from "@/pages/Productions/pages/Satellite/locales/zh";
import AISatellite from "@/pages/Productions/pages/AISatellite/locales/zh";
import WangJiangShan from "@/pages/Productions/pages/WangJiangShan/locales/zh";

import SpaceFlight from "@/pages/ExtendCooperation/SpaceFlight/locales/zh";
import IndustrialPark from "@/pages/ExtendCooperation/IndustrialPark/locales/zh";
import UnitedLaboratory from "@/pages/ExtendCooperation/UnitedLaboratory/locales/zh";

import SmartCity from "@/pages/SmartEarth/pages/SmartCity/locales/zh";
import SmartOcean from "@/pages/SmartEarth/pages/SmartOcean/locales/zh";
import AgricultureForestry from "@/pages/SmartEarth/pages/AgricultureForestry/locales/zh";
import CarbonNeutral from "@/pages/SmartEarth/pages/CarbonNeutral/locales/zh";
import EmergencyManagement from "@/pages/SmartEarth/pages/EmergencyManagement/locales/zh";
import EnvironmentalProtection from "@/pages/SmartEarth/pages/EnvironmentalProtection/locales/zh";
import FinanceInsurance from "@/pages/SmartEarth/pages/FinanceInsurance/locales/zh";

export default {
  ...SmartCity,
  ...SmartOcean,
  ...AgricultureForestry,
  ...CarbonNeutral,
  ...EmergencyManagement,
  ...EnvironmentalProtection,
  ...FinanceInsurance,
  ...WelcomePage,
  ...AboutCompany,
  ...WangJiangShan,
  ...AISatellite,
  ...Satellite,
  ...SpaceFlight,
  ...IndustrialPark,
  ...UnitedLaboratory,
  "导航栏部分":{
    "主页":"主页",
    "主页[描述]":"",
    "产品":"产品",
    "产品[描述]":"地球容颜记录者",
    "产品[望江山一代机]":"望江山一代机",
    "产品[望江山一代机][描述]":"星座系统，全球覆盖",
    "产品[下一代AI卫星平台]":"下一代AI卫星平台",
    "产品[下一代AI卫星平台][描述]":"从我们有的到我们想要的",
    "产品[卫星遥感数据]":"卫星遥感数据",
    "产品[卫星遥感数据][描述]":"让历史可视化",
    "智慧地球":"智慧地球",
    "智慧地球[描述]":"智慧地球",
    "智慧地球[智慧海洋]":"智慧海洋",
    "智慧地球[智慧海洋][描述]":"",
    "智慧地球[应急管理]":"应急管理",
    "智慧地球[应急管理][描述]":"",
    "智慧地球[环境保护]":"环境保护",
    "智慧地球[环境保护][描述]":"",
    "智慧地球[保险期货]":"保险期货",
    "智慧地球[保险期货][描述]":"",
    "智慧地球[智慧城市]":"智慧城市",
    "智慧地球[智慧城市][描述]":"",
    "智慧地球[精准农林]":"精准农林",
    "智慧地球[精准农林][描述]":"",
    "智慧地球[碳中和]":"碳中和",
    "智慧地球[碳中和][描述]":"",
    "展示":"展示",
    "展示[描述]":"展示",
    "拓展合作":"拓展合作",
    "拓展合作[描述]":"拓展合作",
    "拓展合作[航天体验中心]":"航天体验中心",
    "拓展合作[航天体验中心][描述]":"",
    "拓展合作[产业园区]":"产业园区",
    "拓展合作[产业园区][描述]":"",
    "拓展合作[联合实验室]":"联合实验室",
    "拓展合作[联合实验室][描述]":"",
    "关于STAR.VISION":"关于STAR.VISION",
    "关于STAR.VISION[描述]":"公司介绍"
  },
  "页脚部分":{
    "标题":"了解更多",
    "详情":"© 2021 Star.vision 公司保留所有权利。  |  ",
    "隐私政策":"隐私政策",
    "使用条款":"使用条款"
  }
}