/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
// import classnames from "classnames";
// import propTypes from "prop-types";

// import css from "./style.scss";
import useWindowSize from "@/hooks/useWindowSize";
import css from "./style.less";

export default function DivisionContentBlock({title,sub_title,discription,bg_src,color,style,className}){
 
  const {height}=useWindowSize();

  const compose_class=useMemo(()=>classnames(css.division_content_block,className),[className]);

  const compose_style=useMemo(()=>Object.assign({},{
    color,
    minHeight:height*0.3,
    backgroundImage:`url(${bg_src})`
  },style),[height,bg_src,color,style]);

  return (
    <div style={compose_style} className={compose_class}>
      <div className={css.effective_content_container}>
        <div className={css.effective_content}>
          <p>{title}</p>
          <p>{sub_title}</p>
          <p>{discription}</p>
        </div>
      </div>
    </div>
)}


DivisionContentBlock.propTypes={


};
DivisionContentBlock.defaultProps={
  color:"white"
};