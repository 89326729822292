/* eslint-disable react/prop-types */
import React from "react";
// import classnames from "classnames";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
// import propTypes from "prop-types";

// import useWindowSize from "@/hooks/useWindowSize";
// import useResponsive from "@/hooks/useResponsive";
import useProgressiveImg from "@/hooks/useProgressiveImg";
import ContentImageBlock from "@/components/ContentImageBlock";
import BackgroundDiscription from "@/components/BackgroundDiscription";

import ProductionContentBanner from "@/pages/Productions/components/ProductionContentBanner";
import DiscriptionLayoutBlock from "@/pages/Productions/components/DiscriptionLayoutBlock";

import css from "./style.less";
import bg_src from "./assets/bg_src.jpg";
import bg_src_low from "./assets/bg_src.low.jpg";
import block1_bg_image from "./assets/block1_bg_image.jpg";
import block2_bg_image from "./assets/block2_bg_image.jpg";
import block3_bg_image from "./assets/block3_bg_image.jpg";
import block3_bg_content_image from "./assets/block3_bg_content_image.png";

export default function AISatellite(){
  const {t}=useTranslation();

  // const {isDesktop,isMobile}=useResponsive();

  const [current_about_banner]=useProgressiveImg({lowQualitySrc:bg_src_low,highQualitySrc:bg_src});

  return (
    <div>
      <ProductionContentBanner
        bg_src={current_about_banner}
        title={t("下一代AI卫星平台.主题部分.标题")}
        sub_title={t("下一代AI卫星平台.主题部分.副标题")}
      />
      <BackgroundDiscription bg_src={block1_bg_image}>
        <Grid container>
          <Grid item xs={6}>
            <DiscriptionLayoutBlock
              color="white"
              // className={content_block2_class}
              title={t("下一代AI卫星平台.模块简介1.标题")}
              discription={t("下一代AI卫星平台.模块简介1.描述")}
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </BackgroundDiscription>
      <BackgroundDiscription content_className={css.block3_container} bg_src={block2_bg_image}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <DiscriptionLayoutBlock
              color="black"
              title={t("下一代AI卫星平台.模块简介2.标题")}
              discription={t("下一代AI卫星平台.模块简介2.描述")}
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </BackgroundDiscription>
      <BackgroundDiscription bg_src={block3_bg_image}>
        <Grid container>
          <Grid item xs={12}>
            <ContentImageBlock image_src={block3_bg_content_image} />
          </Grid>
          <Grid item xs={12}>
            <DiscriptionLayoutBlock
              color="white"
              title={t("下一代AI卫星平台.模块简介3.标题")}
              discription={t("下一代AI卫星平台.模块简介3.描述")}
            />
          </Grid>
        </Grid>
      </BackgroundDiscription>
    </div>
)}


AISatellite.propTypes={


};
AISatellite.defaultProps={


};