
export default {
  "精准农林":{
    "主题部分":{
      "标题":"精准农林",
      "副标题":""
    },
    "模块描述1":{
      "标题":"现代卫星科技使农林行业如虎添翼",
      "副标题":"洞察入微 精准养护",
      "描述":"高分辨率及多谱段综合图像提供更丰富的潜在/可视化信息，让您随时洞悉土壤状态，精准管理各种作物的生长，为丰产提供助力。"
    },
    "模块分割1":{
      "标题":"AGRICULTURE AND FORESTRY",
      "副标题":"提高作物产量，守护森林健康",
      "描述":"数据驱动的精准农业与地球"
    },
    "模块描述2":{
      "标题":"现代卫星科技助力您防患于未然",
      "副标题":"动态监测 精确监管",
      "描述":"全天候全天时动态监测助力农林日常管理，多谱段 信息综合分析让您对火灾和病虫害灾害一目了然。"
    }
  }
};