/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";

import useProgressiveImg from "@/hooks/useProgressiveImg";
import SatelliteDividerBlock from "@/pages/Productions/components/SatelliteDividerBlock";
import ProductionContentBanner from "@/pages/Productions/components/ProductionContentBanner";
import SatellitePerformanceParameter from "@/pages/Productions/components/SatellitePerformanceParameter";

import bg_src from "./assets/bg_src.jpg";
import bg_src_low from "./assets/bg_src.low.jpg";
import wimg1_bg from "./assets/wimg1_bg.jpg";
import wimg2 from "./assets/wimg2.png";
import wimg2_bg from "./assets/wimg2_bg.jpg";
import wimg3 from "./assets/wimg3.png";
import wimg3_bg from "./assets/wimg3_bg.jpg";

export default function WangJiangShan(){
  const {t}=useTranslation();
  const [current_about_banner]=useProgressiveImg({lowQualitySrc:bg_src_low,highQualitySrc:bg_src});
  return (
    <div>
      <ProductionContentBanner
        bg_src={current_about_banner}
        title={t("望江山一代机.主题部分.标题")}
        sub_title={t("望江山一代机.主题部分.副标题")}
        discription_title={t("望江山一代机.主题部分.描述标题")}
        discription_content={t("望江山一代机.主题部分.描述详情")}
      />
      <SatellitePerformanceParameter
        model_3d="/WJS1WXZZ.html"
        name={t("望江山一代机.模块简介1.名称")}
        performance={JSON.parse(t("望江山一代机.模块简介1.参数性能"))}
      />
      <SatelliteDividerBlock
        bg_src={wimg1_bg}
        title={t("望江山一代机.分割模块1.标题")}
        discription={t("望江山一代机.分割模块1.描述")}
      />
      <SatellitePerformanceParameter
        view_block={wimg2}
        name={t("望江山一代机.模块简介2.名称")}
        performance={JSON.parse(t("望江山一代机.模块简介2.参数性能"))}
      />
      <SatelliteDividerBlock
        bg_src={wimg2_bg}
        title={t("望江山一代机.分割模块2.标题")}
        discription={t("望江山一代机.分割模块2.描述")}
      />
      <SatellitePerformanceParameter
        view_block={wimg3}
        name={t("望江山一代机.模块简介3.名称")}
        performance={JSON.parse(t("望江山一代机.模块简介3.参数性能"))}
      />
      <SatelliteDividerBlock
        bg_src={wimg3_bg}
        title={t("望江山一代机.分割模块3.标题")}
        discription={t("望江山一代机.分割模块3.描述")}
      />
    </div>
)}

WangJiangShan.propTypes={


};
WangJiangShan.defaultProps={


};