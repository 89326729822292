/* eslint-disable react/prop-types */
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React,{useRef,useState,useCallback} from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";

import LevelTwoButton from "../LevelTwoButton";

// import css from "./style.scss";
import css from "./style.less";

function RenderLink({name,link,href,onMouseEnter,onMouseLeave}){
  const {t}=useTranslation();
  if(link){
  return (
    <NavLink to={link} className={css.subject_button} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {t(name)}
    </NavLink>
  )}
  if(href){
    return (
      <a href={href} className={css.subject_button} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {t(name)}
      </a>
  )}
  return null;
}

export default function LevelOneButton({name,href,link,discription,sub_navigation}){
  
  const timmer=useRef();

  const {t}=useTranslation();

  const [display,set_display]=useState(false);

  const handleTaggleShow=useCallback(()=>{
    clearTimeout(timmer.current);
    set_display(true);
  },[]);
  
  const handleTaggleHidden=useCallback(()=>{
    timmer.current=setTimeout(()=>set_display(false),100);
  },[]);

  return (
    <div className={css.subject_button_container}>
      {sub_navigation?(
        <div className={css.subject_button} onMouseEnter={handleTaggleShow} onMouseLeave={handleTaggleHidden}>
          {t(name)}
        </div>
      ):(
        <RenderLink href={href} link={link} name={name} onMouseEnter={handleTaggleShow} onMouseLeave={handleTaggleHidden} />
      )}
      {sub_navigation?(
        <LevelTwoButton
          show={display}
          discription={t(discription)}
          navigation={sub_navigation}
          onTaggleShow={handleTaggleShow}
          onTaggleHidden={handleTaggleHidden}
        />
      ):null}
    </div>
)}


LevelOneButton.propTypes={


};
LevelOneButton.defaultProps={


};