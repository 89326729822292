/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";

// import classnames from "classnames";
// import propTypes from "prop-types";

import useProgressiveImg from "@/hooks/useProgressiveImg";
import ThemeContentBlock from "@/components/ThemeContentBlock";
import PreviewContentBlock from "@/components/PreviewContentBlock";
import DivisionContentBlock from "@/components/DivisionContentBlock";

// import css from "./style.scss";
// import css from "./style.less";
import bg_src from "./assest/bg_src.jpg";
import bg_src_low from "./assest/bg_src.low.jpg";
import icon from "./assest/spaceflightimg1.png";
import block1_bg_src from "./assest/block1_bg_src.jpg";
import block2_bg_src from "./assest/block2_bg_src.jpg";
import block3_bg_src from "./assest/block3_bg_src.jpg";


export default function FinanceInsurance(){
  const {t}=useTranslation();
  const [current_about_banner]=useProgressiveImg({lowQualitySrc:bg_src_low,highQualitySrc:bg_src});
  return (
    <div>
      <ThemeContentBlock
        icon={icon}
        bg_src={current_about_banner}
        title={t("金融与保险.主题部分.标题")}
        discription={t("金融与保险.主题部分.副标题")}
      />
      <PreviewContentBlock
        color="black"
        bg_src={block1_bg_src}
        title={t("金融与保险.模块描述1.标题")}
        sub_title={t("金融与保险.模块描述1.副标题")}
        discription={t("金融与保险.模块描述1.描述")}
      />
      <DivisionContentBlock
        bg_src={block2_bg_src}
        title={t("金融与保险.分割模块1.标题")}
        sub_title={t("金融与保险.分割模块1.副标题")}
        discription={t("金融与保险.分割模块1.描述")}
      />
      <PreviewContentBlock
        color="black"
        bg_src={block3_bg_src}
        title={t("金融与保险.模块描述2.标题")}
        sub_title={t("金融与保险.模块描述2.副标题")}
        discription={t("金融与保险.模块描述2.描述")}
      />
    </div>
)}


FinanceInsurance.propTypes={


};
FinanceInsurance.defaultProps={


};