/* eslint-disable react/prop-types */
import React from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";

import css from "./style.less";

export default function DetailDividerBlock(props){
  const {children}=props;
  return (
    <div className={css.detail_divider_block}>
      {children}
      <div className={css.title_divider}>
        <strong>WONDERJOURNEY</strong>
      </div>
    </div>
)}


DetailDividerBlock.propTypes={


};
DetailDividerBlock.defaultProps={


};