/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
import BackgroundBlock from "@/components/BackgroundBlock";

import ContentBlock from "@/pages/AboutCompany/elements/ContentBlock";

import css from "./style.less";
import icon1 from "./assets/icon1.png";
import icon2 from "./assets/icon2.png";
import icon3 from "./assets/icon3.png";


export default function ContentBlock1(){

  const {t}=useTranslation();

  const {isMobile,isDesktop}=useResponsive();

  const computed_xs_spacing=useMemo(()=>{
    if(isMobile){
      return 12;
    }
    if(isDesktop){
      return 4;
    }
    return 4;
  },[isMobile,isDesktop]);

  return (
    <BackgroundBlock className={css.content_block1_container}>
     <div className={css.title}>{t("关于STAR.VISION.模块简介1.标题")}</div>
      <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={computed_xs_spacing}>
          <div className={css.icon_image} style={{backgroundImage:`url(${icon1})`}} />
          <ContentBlock content={t("关于STAR.VISION.模块简介1.模块1")} />
        </Grid>
        <Grid item xs={computed_xs_spacing}>
          <div className={css.icon_image} style={{backgroundImage:`url(${icon2})`}} />
          <ContentBlock content={t("关于STAR.VISION.模块简介1.模块2")} />
        </Grid>
        <Grid item xs={computed_xs_spacing}>
          <div className={css.icon_image} style={{backgroundImage:`url(${icon3})`}} />
          <ContentBlock content={t("关于STAR.VISION.模块简介1.模块3")} />
        </Grid>
      </Grid>
    </BackgroundBlock>
)}


ContentBlock1.propTypes={


};
ContentBlock1.defaultProps={


};