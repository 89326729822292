
export default {
  "产业园区":{
    "主题部分":{
      "标题":"",
      "副标题":"INDUSTRIAL PARK"
    },
    "模块简介1":{
      "标题":"Get together",
      "副标题":"Industrial aggregation and vertical integration",
      "描述":"STAR.VISION is committed to R&D and manufacturing of satellite, it will drive the aggregation of upstream and downstream industries, promote the formation of the whole industrial cluster from R&D to design, from production to application, and from hardware to software, stimulate innovation, achieve industrial upgrading, and help economic development."
    },
    "模块简介2":{
      "标题":"Satellite application",
      "副标题":"Suitable scenario, Application first",
      "描述":"Satellite network is the infrastructure of the new generation of information technology. Application of Satellite shall generate 20 times more output value compared to network construction investment. Satellite application development among rich scenarios can be conducted as priority when industrial park is constructed."
    }
  }
};