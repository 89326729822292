/* eslint-disable react/prop-types */
import React from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";

import FlexRow from "@/components/FlexRow";
import FlexCol from "@/components/FlexCol";

// import css from "./style.scss";
import css from "./style.less";

export default function SubjectBlock({title,subject_tree}){
  const {t}=useTranslation();
  return (
    <div className={css.subject_block}>
      <div className={css.subject_title}>{t(title)}</div>
      <FlexRow justify="start" align="top">
        <FlexCol style={{marginRight:20}} justify="start" align="start">
          {subject_tree.slice(0,4).map(({title,link})=>{
            return (
              <NavLink key={title} className={css.subject_item} to={link}>
                {t(title)}
              </NavLink>
          )})}
        </FlexCol>
        <FlexCol style={{marginRight:20}} justify="start" align="start">
          {subject_tree.slice(4,8).map(({title,link})=>{
            return (
              <NavLink key={title} className={css.subject_item} to={link}>
                {t(title)}
              </NavLink>
          )})}
        </FlexCol>
      </FlexRow>
    </div>
)}


SubjectBlock.propTypes={


};
SubjectBlock.defaultProps={


};