/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
import BackgroundBlock from "@/components/BackgroundBlock";

import ContentBlock from "@/pages/AboutCompany/elements/ContentBlock";

import css from "./style.less";
import bg from "./assest/bg.jpg";

export default function ContentBlock3(){

  const {t}=useTranslation();

  const {isMobile,isDesktop}=useResponsive();

  const computed_xs_spacing=useMemo(()=>{
    if(isMobile){
      return 12;
    }
    if(isDesktop){
      return 4;
    }
    return 4;
  },[isMobile,isDesktop]);

  return (
    <BackgroundBlock src={bg} className={css.content_block}>
      <Grid container>
        <Grid xs={computed_xs_spacing} item className={css.subject_block}>
          <ContentBlock content={t("关于STAR.VISION.模块简介2.模块1")} />
        </Grid>
        <Grid xs={computed_xs_spacing} item className={css.subject_block}>
          <ContentBlock content={t("关于STAR.VISION.模块简介2.模块2")} />

        </Grid>
        <Grid xs={computed_xs_spacing} item className={css.subject_block}>
          <ContentBlock content={t("关于STAR.VISION.模块简介2.模块3")} />
        </Grid>
      </Grid>
    </BackgroundBlock>
)}


ContentBlock3.propTypes={


};
ContentBlock3.defaultProps={


};