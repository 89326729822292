/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";

import BackgroundBlock from "@/components/BackgroundBlock";

// import css from "./style.scss";
import css from "./style.less";
import bg from "./assest/bg.jpg";

export default function ContentBlock5(){
  const {t}=useTranslation();
  return (
    <BackgroundBlock src={bg} className={css.content_block5}>
      <strong>
        {t("关于STAR.VISION.分割模块2.口号")}
      </strong>
      <div>
        <div>{t("关于STAR.VISION.分割模块2.main")}</div>
        <div className={css.cont}>{t("关于STAR.VISION.分割模块2.main2")}</div>
        <div>{t("关于STAR.VISION.分割模块2.联系方式")}</div>
        <address>{t("关于STAR.VISION.分割模块2.地址")}</address>
        <div>{t("关于STAR.VISION.分割模块2.邮箱")}</div>
      </div>
    </BackgroundBlock>
)}


ContentBlock5.propTypes={


};
ContentBlock5.defaultProps={


};
