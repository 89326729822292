
export default {
  "航天体验中心":{
    "主题部分":{
      "标题":"航天体验中心",
      "副标题":"SPACE EXPERIENCE CENTER"
    },
    "模块简介1":{
      "标题":"圆梦太空",
      "副标题":"VR互动，非凡体验",
      "描述":"利用VR虚拟现实技术实现沉浸式互动，真实模拟空间失重环境，营造月球、太空、空间站等多种逼真太空环境，为体验者提供身临其境的非凡航天体验。"
    },
    "模块简介2":{
      "标题":"启航未来",
      "副标题":"内容丰富，寓教于乐",
      "描述":"以寓教于乐的方式进行全民科普。通过航天简史、模拟发射、行星探索、太空遨游等丰富内容，让体验者在趣味中了解奇幻的太空世界和航天知识。通过丰富的科技互动体验，让体验者感受到航天的魅力，传承征服星辰大海的梦想。"
    }
  }
};