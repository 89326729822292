
export default {
  "望江山一代机":{
    "拖动查看":"DRAG THE MOUSE TO VIEW",
    "望江山":"",
    "整星系列":"",
    "主题部分":{
      "标题":"WONDER JOURNEY CONSTELLATION",
      "副标题":"Global Coverage",
      "描述标题":"Deep insight to help your strategic decision-making",
      "描述详情":"Star.Vision is committed to using high-performance and low-cost technologies for agile innovation in the aerospace field. With the help of the constellation covering the world, we can quickly refresh the global data set. Based* on the leading AI technology, we can analyze and process data in orbit and provide on-demand information directly. We can easily and efficiently meet your needs through our API, web application and be integrated into your own application. We keep on improving our satellite technology and enriching the types of satellites, which provides new opportunities for human beings to acquire more and more comprehensive knowledge about the earth from different angles and dimensions in a low-cost way"
    },
    "模块简介1":{
      "名称":"Optical Satellite WJ-1",
      "参数性能":JSON.stringify([
        "Resolution / Hardware Resolution: sub-meter; AI Super-resolution: less than 0.4 meter",
        "Weight / 60KG (additional 20KG payload expansion capacity reserved)",
        "The imaging range per satellite per day / 100,000 square kilometers",
        "Data transmission rate / 450Mbps"
      ])
    },
    "分割模块1":{
      "标题":"Clear vision leads to unique inspiration",
      "描述":"Provide high-resolution field of view by powerful optical components. The highly intelligent satellite AI can directly complete preprocessing on demand. You can easily access image data through API and web application, as well as easily integrate analysis-ready planetary data sets into your existing tools and workflows"
    },
    "模块简介2":{
      "名称":"SAR satellite",
      "参数性能":JSON.stringify([
        "Resolution / 1 meter",
        "Weight / 100KG",
        "Instantaneous field of view / 7km x 6km",
        "Payload duty time / more than 10 minutes every revolution around the Earth"
      ])
    },
    "分割模块2":{
      "标题":"Synthetic Aperture Radar Satellite",
      "描述":"High-performance synthetic aperture radar can penetrate clouds, night darkness and ground surface to get the latest and most in-depth information about the earth at all times. Provide important information in agriculture, geomorphological monitoring, prospecting, archaeology, natural resource management, etc."
    },
    "模块简介3":{
      "名称":"IoT Satellite WJ-3",
      "参数性能":JSON.stringify([
        "Frequency / LoRa",
        "Weight / less than 30KG",
        "Designed lifecycle/ more than 5 years"
      ])
    },
    "分割模块3":{
      "标题":"Space based IoT system empowers your application",
      "描述":"IoT satellites operating inlow Earth orbit provide high-speed, wide-area IoT connection services,seamlessly working with ground IoT network, and truly realize everythinginterconnected."
    }
  }
};