/* eslint-disable react/prop-types */
import React from "react";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
// import propTypes from "prop-types";

import useProgressiveImg from "@/hooks/useProgressiveImg";
import PreviewContentBlock from "@/components/PreviewContentBlock";
import BackgroundDiscription from "@/components/BackgroundDiscription";

import ProductionContentBanner from "@/pages/Productions/components/ProductionContentBanner";
import DiscriptionLayoutBlock from "@/pages/Productions/components/DiscriptionLayoutBlock";


// import css from "./style.less";
import bg_src from "./assets/bg_src.jpg";
import bg_src_low from "./assets/bg_src.low.jpg";
import wimg1_bg from "./assets/wimg1_bg.jpg";
import wimg2_bg from "./assets/wimg2_bg.jpg";
import wimg2_bg_image from "./assets/wimg2_bg_image.jpg";

const CustmerImg=styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function Satellite(){

  const {t}=useTranslation();

  const [current_about_banner]=useProgressiveImg({lowQualitySrc:bg_src_low,highQualitySrc:bg_src});

  return (
    <div>
      <ProductionContentBanner
        bg_src={current_about_banner}
        title={t("卫星遥感数据.主题部分.标题")}
        sub_title={t("卫星遥感数据.主题部分.副标题")}
        discription_title={t("卫星遥感数据.主题部分.描述标题")}
        discription_content={t("卫星遥感数据.主题部分.描述详情")}
      />
      <PreviewContentBlock
        color="white"
        bg_src={wimg1_bg}
        title={t("卫星遥感数据.模块简介1.标题")}
        sub_title={t("卫星遥感数据.模块简介1.副标题")}
        discription={JSON.parse(t("卫星遥感数据.模块简介1.描述"))}
      />
      <BackgroundDiscription bg_src={wimg2_bg}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <DiscriptionLayoutBlock
              color="black"
              title={t("卫星遥感数据.模块简介2.标题")}
              sub_title={t("卫星遥感数据.模块简介2.副标题")}
              discription={t("卫星遥感数据.模块简介2.描述")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustmerImg src={wimg2_bg_image} alt="" />
          </Grid>
        </Grid>
      </BackgroundDiscription>
    </div>
)}


Satellite.propTypes={


};
Satellite.defaultProps={


};