
export default {
  "关于STAR.VISION":{
    "模块简介1":{
      "标题":"AI卫星研制·遥感数据挖掘·星座投资运营",
      "模块1":"%%23%%颗卫星的研制经验",
      "模块2":"%%5%%颗在轨运行中",
      "模块3":"为%%40%%余颗卫星提供部组件"
    },
    "分割模块1":{
      "副标题":"空间探索，因合作而无限可能。",
      "描述":"因势而聚 梦指星河"
    },
    "模块简介2":{
      "模块1":"%%38%%个国家开展业务",
      "模块2":"%%4%%类%%18%%个成熟方案",
      "模块3":"%%5%%个国家%%7%%个合作伙伴签订合作协议",
    },
    "模块简介3":{
      "块级标题":"国际化布局",
      "地区名称1":{
        "标题":"中东地区",
        "描述":"沙特、阿曼、阿联酋、卡塔尔、科威特"
      },
      "地区名称2":{
        "标题":"非洲地区",
        "描述":"埃及、肯尼亚、埃塞尔比亚、南非、坦桑尼亚、毛里塔尼亚、摩洛哥、津巴布韦、安哥拉、加纳、赞比亚布基纳法索"
      },
      "地区名称3":{
        "标题":"欧洲地区",
        "描述":"英国、法国"
      },
      "地区名称4":{
        "标题":"美洲地区",
        "描述":"秘鲁、阿根廷、智利、多米尼加、巴拿马"
      },
      "地区名称5":{
        "标题":"东南亚地区",
        "描述":"柬埔寨、老挝、新加坡、印尼、马来西亚、菲律宾、巴基斯坦、斯里兰卡、尼泊尔、斐济"
      }
    },
    "分割模块2":{
      "口号":"地卫二空间技术(杭州)有限公司（STAR.VISION）是一家全球化太空智能卫星公司",
      "main": "主营：AI卫星的设计与研制、智能星座投资运营、SAAS决策智慧平台与数据应用服务",
      "main2": "地卫二技术团队是我国最早使用工业级元器件研制卫星的团队，在AI卫星领域拥有全球领先的技术储备，自研的智能卫星技术性能为全球同行顶尖水平的10倍量级。于IEEE顶级会议CVPR2023、CVPR2022全部对地观测赛道荣获3项亚军及一项多国数据集冠军，是唯一连续上榜的航天企业。",
      "联系方式":"联系方式: 0571-86227683",
      "地址":"地址：浙江省杭州市西湖区云栖小镇鹏辉产业园2号楼4层",
      "邮箱":"邮箱：SV@STAR.VISION"
    }
  }
};
