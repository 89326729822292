/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";

import useProgressiveImg from "@/hooks/useProgressiveImg";
import ImageBanner from "../../elements/ImageBanner";
import BannerGhostButton from "../../elements/BannerGhostButton";

import HorizontalBlock from "../../layouts/HorizontalBlock";

import css from "./style.less";
import banner4 from "../../assets/banner4.jpg";
import banner4_low from "../../assets/banner4.low.jpg";


export default function Banner4(){
  const {t}=useTranslation();
  const [current_about_banner]=useProgressiveImg({lowQualitySrc:banner4_low,highQualitySrc:banner4});
  return (
    <ImageBanner className={css.banner4} image_src={current_about_banner}>
      <HorizontalBlock title={t("轮播图部分.轮播图4.标题文字")} discription={t("轮播图部分.轮播图4.描述文字")}>
        <BannerGhostButton link="/productions/ai_satellite">
          {t("轮播图部分.轮播图4.按钮文字")}
        </BannerGhostButton>
      </HorizontalBlock>
    </ImageBanner>
)}


Banner4.propTypes={


};
Banner4.defaultProps={


};