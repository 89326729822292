/* eslint-disable react/prop-types */
import React from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";

import useWindowSize from "@/hooks/useWindowSize";
import useProgressiveImg from "@/hooks/useProgressiveImg";

import about_banner from "./assets/about_banner.jpg";
import about_banner_low from "./assets/about_banner.low.jpg";

import PageBanner from "./components/PageBanner";
import ContentBlock1 from "./components/ContentBlock1";
import ContentBlock2 from "./components/ContentBlock2";
import ContentBlock3 from "./components/ContentBlock3";
import ContentBlock4 from "./components/ContentBlock4";
import ContentBlock5 from "./components/ContentBlock5";

export default function AboutCompany(){
  const {width,height}=useWindowSize();
  const [current_about_banner]=useProgressiveImg({lowQualitySrc:about_banner_low,highQualitySrc:about_banner});
  return (
    <div>
      <div style={{width,height}}>
        <PageBanner image_src={current_about_banner} />
      </div>
      <ContentBlock1 />
      <ContentBlock2 />
      <ContentBlock3 />
      <ContentBlock4 />
      <ContentBlock5 />
    </div>
)}


AboutCompany.propTypes={


};
AboutCompany.defaultProps={


};