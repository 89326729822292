/* eslint-disable react/prop-types */
import React from "react";
import classnames from "classnames";
import {Carousel} from "react-responsive-carousel";

import css from "./style.less";
import Banner1 from "./components/Banner1";
import Banner2 from "./components/Banner2";
import Banner3 from "./components/Banner3";
import Banner4 from "./components/Banner4";
import Banner5 from "./components/Banner5";

function renderIndicator(onClick,isActive){
  return (
    <div
      onClick={onClick}
      style={{width:10,height:10,margin:"0 6px"}}
      className={classnames({
        [css.indicator]:true,
        [css.indicator_active]:isActive
      })} 
    />
)}

export default function WelcomePage(){
  return (
    <Carousel
      autoPlay
      stopOnHover
      emulateTouch
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      renderIndicator={renderIndicator}
    >
   
      {/*
	      <Banner5 />
      <Banner1 />
      */}
      <Banner2 />
      <Banner3 />
      <Banner4 />
    </Carousel>
)}


WelcomePage.propTypes={


};
WelcomePage.defaultProps={


}
