
export default {
  "下一代AI卫星平台":{
    "主题部分":{
      "标题":"CHANGE IMMEDIATLY. FROM WHAT WE HAVE TO WHAT YOU WANT.",
      "副标题":"NEXT GENERATION AI SATELLITE PLATFORM",
      "描述标题":"",
      "描述详情":""
    },
    "模块简介1":{
      "标题":"Strongest AI capability",
      "描述":"Transplant the computing power of the same level of autopilot to the satellite Support advanced algorithms such* as F-R-CNN and YOLO.Vision AI algorithm with independent intellectual property rights, autonomous learning in orbit."
    },
    "模块简介2":{
      "标题":"High speed in-orbit OTA update",
      "描述":"Hardware* virtualization, diverse software* loading on demand."
    },
    "模块简介3":{
      "标题":"Commercial native cloud operating system",
      "描述":"Adopt commercial large-scale application operating system to enable powerful function and stable operation Based on the native Cloud Architecture, it is easy to build the space cloud and the Space-Ground integrated cloud to realize the sharing of computing power."
    }
  }
};