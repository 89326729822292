import WelcomePage from "@/pages/WelcomePage/locales/en";
import AboutCompany from "@/pages/AboutCompany/locales/en";

import Satellite from "@/pages/Productions/pages/Satellite/locales/en";
import AISatellite from "@/pages/Productions/pages/AISatellite/locales/en";
import WangJiangShan from "@/pages/Productions/pages/WangJiangShan/locales/en";

import SpaceFlight from "@/pages/ExtendCooperation/SpaceFlight/locales/en";
import IndustrialPark from "@/pages/ExtendCooperation/IndustrialPark/locales/en";
import UnitedLaboratory from "@/pages/ExtendCooperation/UnitedLaboratory/locales/en";

import SmartCity from "@/pages/SmartEarth/pages/SmartCity/locales/en";
import SmartOcean from "@/pages/SmartEarth/pages/SmartOcean/locales/en";
import AgricultureForestry from "@/pages/SmartEarth/pages/AgricultureForestry/locales/en";
import CarbonNeutral from "@/pages/SmartEarth/pages/CarbonNeutral/locales/en";
import EmergencyManagement from "@/pages/SmartEarth/pages/EmergencyManagement/locales/en";
import EnvironmentalProtection from "@/pages/SmartEarth/pages/EnvironmentalProtection/locales/en";
import FinanceInsurance from "@/pages/SmartEarth/pages/FinanceInsurance/locales/en";


export default {
  ...SmartCity,
  ...SmartOcean,
  ...AgricultureForestry,
  ...CarbonNeutral,
  ...EmergencyManagement,
  ...EnvironmentalProtection,
  ...FinanceInsurance,
  ...WelcomePage,
  ...AboutCompany,
  ...WangJiangShan,
  ...AISatellite,
  ...Satellite,
  ...SpaceFlight,
  ...IndustrialPark,
  ...UnitedLaboratory,
  "导航栏部分":{
    "主页":"HOME",
    "主页[描述]":"",
    "产品":"PRODUCTS",
    "产品[描述]":"",
    "产品[望江山一代机]":"WONDER JOURNEY SERIES SATELLITES",
    "产品[望江山一代机][描述]":"",
    "产品[下一代AI卫星平台]":"NEXT GENERATION AI SATELLITE PLATFORM",
    "产品[下一代AI卫星平台][描述]":"",
    "产品[卫星遥感数据]":"REMOTE SENSING DATA",
    "产品[卫星遥感数据][描述]":"",
    "智慧地球":"SMART EARTH SOLUTION",
    "智慧地球[描述]":"SMART EARTH SOLUTION",
    "智慧地球[智慧海洋]":"SMART OCEAN",
    "智慧地球[智慧海洋][描述]":"",
    "智慧地球[应急管理]":"EMERGENCY MANAGEMENT",
    "智慧地球[应急管理][描述]":"",
    "智慧地球[环境保护]":"ENVIRONMENTAL PROTECTION",
    "智慧地球[环境保护][描述]":"",
    "智慧地球[保险期货]":"INSURANCE AND FUTURES",
    "智慧地球[保险期货][描述]":"",
    "智慧地球[智慧城市]":"SMART CITY",
    "智慧地球[智慧城市][描述]":"",
    "智慧地球[精准农林]":"PRECISION AGRICULTURE AND FORESTRY",
    "智慧地球[精准农林][描述]":"",
    "智慧地球[碳中和]":"CARBON EMISSION",
    "智慧地球[碳中和][描述]":"",
    "展示":"DEMO",
    "展示[描述]":"",
    "拓展合作":"COOPERATION",
    "拓展合作[描述]":"",
    "拓展合作[航天体验中心]":"SPACE EXPERIENCE CENTER",
    "拓展合作[航天体验中心][描述]":"",
    "拓展合作[产业园区]":"INDUSTRIAL PARK",
    "拓展合作[产业园区][描述]":"",
    "拓展合作[联合实验室]":"JOINT LABORATORY",
    "拓展合作[联合实验室][描述]":"",
    "关于STAR.VISION":"ABOUT",
    "关于STAR.VISION[描述]":"COMPANY PROFILE"
  },
  "页脚部分":{
    "标题":"LEARN MORE",
    "详情":"©2021 STAR.VISION All rights reserved.  | ",
    "隐私政策":"Privacy policy",
    "使用条款":"Terms of use"
  }
};