/* eslint-disable react/prop-types */
import React from "react";
// import classnames from "classnames";
// import propTypes from "prop-types";

import FlexCol from "@/components/FlexCol";
import css from "./style.less";

export default function VerticalBlock({children}){
  return (
    <FlexCol className={css.vertical_block}>
      {children}
    </FlexCol>
)}


VerticalBlock.propTypes={


};
VerticalBlock.defaultProps={


};