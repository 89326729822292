
export default {
  "联合实验室":{
    "主题部分":{
      "标题":"",
      "副标题":"JOINT LABORATORY"
    },
    "模块简介1":{
      "标题":"Frontier technology development in aerospace",
      "副标题":"Cooperative exploration, openness and inclusiveness",
      "描述":"Carry out forward-looking, pioneering and exploratory research on micro-spacecraft, including intelligent modular and scalable microsatellite platform, high-power energy acquisition technology, inter-satellitelaser communication technology, high impulse micro-propulsion technology, and spaceIntelligent research, etc."
    },
    "模块简介2":{
      "标题":"Rapid transformation ofresearch results",
      "副标题":"Integration and innovation,sharing and transformation",
      "描述":"Integrate space and IT cutting-edge technologies, continue to innovate and develop, rapidly transform research results into aerospace products,promote and deploy Big Data projects such as Digital City, Auto-Pilot Driving, Social Emergency Response System, etc.."
    }
  }
};