
export default {
  "智慧城市":{
    "主题部分":{
      "标题":"智慧城市",
      "副标题":"",
      "描述":""
    },
    "模块描述1":{
      "标题":"十分钟前你的城市发生了什么?",
      "副标题":"城市大脑 手眼通天",
      "描述":"西湖景区现在有多少条船？你所行驶的道路有多少辆车？安全事故的救援进展如何？火灾过火面积已有多少？城市河流的污染来自哪里？洪流已经到达了哪个区域？无论是 智慧交通，还是网格管理，都离不开城市级的天地一体化网络。地卫二星座计划囊括300颗遥感卫星，可对全球任意点进行十分钟内重访。"
    },
    "分割模块1":{
      "标题":"SMART CITY",
      "副标题":"视角决定思维方式，AI成就智慧城市。",
      "描述":"全方位联动触及城市脉搏并快速响应"
    },
    "模块描述2":{
      "标题":"十分钟后你的城市将发生什么？",
      "副标题":"见解独到 源于所见",
      "描述":"你所前往的景区是否即将进入拥堵状态？学校运动会和明星演唱会是否可以顺利开幕？逃逸的车辆可能进入哪个布控区域？城市内涝区域是否有疏散必要？今天预计有多少架飞机从萧山机场起飞？丰富的历史数据和高时间敏感度的地理信息，辅以地卫二专属AI算法，让城市管理者运筹帷幄、掌控未来。"
    }
  }

};