/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
// import classnames from "classnames";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
import useWindowSize from "@/hooks/useWindowSize";
import css from "./style.less";

export default function PreviewContentBlock({color,title,sub_title,discription,bg_src,style,className}){

  const {height}=useWindowSize();

  const {isMobile,isDesktop}=useResponsive();

  const compose_class=useMemo(()=>classnames(css.preview_content_block,className),[className]);

  const effective_content_container_class=useMemo(()=>classnames({
    [css.effective_content_container_desktop]:isDesktop,
    [css.effective_content_container_mobile]:isMobile,
  }),[isMobile,isDesktop]);

  const effective_content_class=useMemo(()=>classnames({
    [css.effective_content]:true,
    [css.effective_content_desktop]:isDesktop,
    [css.effective_content_mobile]:isMobile
  }),[isMobile,isDesktop]);

  const compose_style=useMemo(()=>Object.assign({},{
    color,
    minHeight:height*0.6,
    backgroundImage:`url(${bg_src})`
  },style),[color,bg_src,height,style]);

  const compose_divider_style=useMemo(()=>({backgroundColor:color}),[color]);

  return (
    <div style={compose_style} className={compose_class}>
      <div className={effective_content_container_class}>
        <div className={effective_content_class}>
          <strong className={css.title}>{title}</strong>
          <strong className={css.sub_title}>{sub_title}</strong>
          <div style={compose_divider_style} className={css.divider} />
          {(discription instanceof Array)?(
            <ul className={css.discription}>
              {discription.map((discription_detail)=>{
                return (
                  <li key={discription_detail}>
                    {discription_detail}
                  </li>
              )})}
            </ul>
          ):(
            <div className={css.discription}>{discription}</div>
          )}
        </div>
      </div>
    </div>
)}


PreviewContentBlock.propTypes={


};
PreviewContentBlock.defaultProps={
  color:"black"
};