
export default {
  "应急管理":{
    "主题部分":{
      "标题":"应急管理",
      "描述":""
    },
    "模块描述1":{
      "标题":"应急响应",
      "副标题":"天地一体 紧急联动",
      "描述":"突发事件的紧急响应，需要有及时准确的空间地理信息辅助决策。严重灾害通信中断，卫星网络是必不可少的通信保障。"
    },
    "分割模块1":{
      "标题":"EMERGENCY MANAGEMENT",
      "副标题":"治未然，保障家园的正确方式",
      "描述":"精准触及城市脉搏并快速响应"
    },
    "模块描述2":{
      "标题":"灾后评估",
      "副标题":"事件复盘 精准重建",
      "描述":"问题在哪里？漏洞在哪里？哪些可以完善？哪些可以提高？灾后的复盘和重建，与应急响同等重要。决策者需要随时准备好应对下一场挑战。"
    }
  }
};