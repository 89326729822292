
export default {
  "碳中和":{
    "主题部分":{
      "标题":"CARBON EMISSION",
      "副标题":"",
      "描述":""
    },
    "模块描述1":{
      "标题":"Tackling climate problems and achieving sustainable development",
      "副标题":"Protect our home",
      "描述":"With the development of economy, the environment around us is changing day by day. More and more severe natural environment has brought tough challenges to human survival. We must adhere to the road of sustainable development, fight climate change and build a beautiful home."
    },
    "分割模块1":{
      "标题":"CARBON EMISSION MONITORING",
      "副标题":"Change perspective immediately, show solicitude for earth warming",
      "描述":"STAR.VISION pay attention to the carbon emission detection and alarm"
    },
    "模块描述2":{
      "标题":"Implementing the quota trading mechanism of ecological resources",
      "副标题":"Detailed and reliable third party data to ensure carbon quota trading",
      "描述":"Provide detailed and reliable data, accurately and quantitatively monitor carbon emissions, and ensure the effective implementation of carbon quota trading"
    }
  }
};