/* eslint-disable react/prop-types */
import React,{useCallback} from "react";
import {useNavigate} from "react-router-dom";
// import classnames from "classnames";
// import propTypes from "prop-types";
import FlexRow from "@/components/FlexRow";
import LanguageSwitch from "@/layouts/elements/LanguageSwitch";

import useResponsive from "@/hooks/useResponsive";

// import FlexCol from "@/components/FlexCol";

import css from "./style.less";
import LevelOneButton from "./elements/LevelOneButton";

import logo from "@/layouts/assest/logo.png";

export default function DesktopHeader({navigation}){

  const navigate=useNavigate();

  const {isMobile}=useResponsive();

  const handleClick=useCallback(()=>navigate("/welcome"),[navigate]);

  return (
    <FlexRow className={css.header_block_container}>
      <FlexRow className={css.logo_container}>
        <img className={css.logo} src={logo} alt="" onClick={handleClick} />
      </FlexRow>
      {isMobile?null:(
        <FlexRow flex={1} justify="start" className={css.navigation}>
          {navigation.map(({name,link,href,discription,sub_navigation})=>{
            return (
              <LevelOneButton
                key={name}
                name={name}
                link={link}
                href={href}
                discription={discription}
                sub_navigation={sub_navigation}
              />
          )})}
        </FlexRow>
      )}
      <FlexRow align="center" className={css.select_language}>
        <LanguageSwitch type="en" language="ENGLISH" />
        <LanguageSwitch type="zh" language="中文(简体)" />
      </FlexRow>
    </FlexRow>
)}


DesktopHeader.propTypes={


};
DesktopHeader.defaultProps={


};