/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";

// import classnames from "classnames";
// import propTypes from "prop-types";

import BackgroundBlock from "@/components/BackgroundBlock";

// import css from "./style.scss";
import css from "./style.less";
import background from "./assets/background.jpg";

export default function ContentBlock(){
  const {t}=useTranslation();
  return (
    <BackgroundBlock src={background} className={css.content_block}>
      <div>
        <strong>Cooperation</strong>
        <span> value</span>
      </div>
      <div className={css.line2}>
        {t("关于STAR.VISION.分割模块1.副标题")}
      </div>
      <div className={css.line3}>
        {t("关于STAR.VISION.分割模块1.描述")}
      </div>
    </BackgroundBlock>
)}


ContentBlock.propTypes={


};
ContentBlock.defaultProps={


};