/* eslint-disable react/prop-types */
import React,{useMemo} from "react";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
// import propTypes from "prop-types";

import useResponsive from "@/hooks/useResponsive";
import css from "./style.less";

export default function Model3dIframe(props){
  const {model_3d}=props;

  const {t}=useTranslation();

  const {isMobile,isDesktop}=useResponsive();

  const responsive_class=useMemo(()=>classnames({
    [css.model_3d_iframe_container]:true,
    [css.model_3d_iframe_container_mobile]:isMobile,
    [css.model_3d_iframe_container_desktop]:isDesktop
  }),[isMobile,isDesktop]);

  return (
    <div className={responsive_class}>
      <iframe 
        src={model_3d}
        frameBorder={0}
        allowFullScreen
        title={model_3d}
        className={css.model_3d_iframe}
      />
      <div className={css.tip_text}>{t("望江山一代机.拖动查看")}</div>
    </div>
)}


Model3dIframe.propTypes={


};
Model3dIframe.defaultProps={


};