/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import Bowser from "bowser";
import ReactDOM from "react-dom";
import {I18nextProvider} from "react-i18next";
import {BrowserRouter} from "react-router-dom";

import init18n from "@/init18n";
import MainBlock from "@/entry";

/** 
 * webpack-hot-middleware的客户端更新代码 
 * @see https://github.com/webpack-contrib/webpack-hot-middleware/blob/master/example/client.js
 * **/
if(process.env.NODE_ENV==="development"){
  if(module.hot){
    module.hot.accept();
    module.hot.dispose(function () {
      console.log("module.hot.dispose");
    });
  }
}

const {platform}=Bowser.parse(window.navigator.userAgent);
/** 
 * 移动端为了防止滚动的时候移动全局webview
 * 需要取消html,body,#root的默认事件
 * **/
if(platform.type=="mobile"){
  document.body.addEventListener("touchcancel", function(e) {
    if(e.target.elementType==="html"){
      e.preventDefault();
    }
    if(e.target.elementType==="body"){
      e.preventDefault();
    }
    if(e.target.id==="root"){
      e.preventDefault();
    }
    return false;
  }, {
    passive: false
  });
  document.body.addEventListener("touchend", function(e) {
    if(e.target.tagName==="html"){
      e.preventDefault();
    }
    if(e.target.tagName==="body"){
      e.preventDefault();
    }
    if(e.target.id==="root"){
      e.preventDefault();
    }
    return false;
  }, {
    passive: false
  });
  document.body.addEventListener("touchstart", function(e) {
    if(e.target.tagName==="html"){
      e.preventDefault();
    }
    if(e.target.tagName==="body"){
      e.preventDefault();
    }
    if(e.target.id==="root"){
      e.preventDefault();
    }
    return false;
  }, {
    passive: false
  });
  document.body.addEventListener("touchmove", function(e) {
    if(e.target.tagName==="html"){
      e.preventDefault();
    }
    if(e.target.tagName==="body"){
      e.preventDefault();
    }
    if(e.target.id==="root"){
      e.preventDefault();
    }
    return false;
  }, {
    passive: false
  });
}

ReactDOM.hydrate((
  <I18nextProvider i18n={init18n(window.language)}>
    <BrowserRouter basename={window.language}>
      <MainBlock />
    </BrowserRouter>
  </I18nextProvider>
),document.getElementById("root"));
