
export default {
  "望江山一代机":{
    "拖动查看":"拖动查看",
    "望江山":"望江山",
    "整星系列":"整星系列",
    "主题部分":{
      "标题":"望江山卫星星座",
      "副标题":"星座系统、全球覆盖",
      "描述标题":"观容识变 运筹帷幄",
      "描述详情":"地卫二致力于使用高性能低成本的技术在航天领域进行快速创新。 借助新一代技术，通过覆盖全球的星座，快速刷新全球数据集，并可以按需提供分析处理提取后的信息，通过我们的API、web应用程序和集成轻松高效满足您的需求。"
    },
    "模块简介1":{
      "名称":"光学卫星 WJ-1",
      "参数性能":JSON.stringify([
        "分辨率 / 硬件分辨率-亚米级， AI分辨率-小于0.4米",
        "质量 / 60KG（额外预留20KG载荷拓展能力）",
        "单日单星可成像范围 / 10万平方公里",
        "数传速率/450Mbps"
      ])
    },
    "分割模块1":{
      "标题":"视野清 才能见解独到",
      "描述":"强有力的光学组件提供高分辨率视野，提供不同以往的独特视角。高度智能化的星上AI可直接按需完成预处理。 您可以通过API、Web应用程序和集成轻松访问您的图像数据，也可以方便地将分析就绪的行星数据集集成到您现有的工具和工作流程中。"
    },
    "模块简介2":{
      "名称":"整星系列",
      "参数性能":JSON.stringify([
        "分辨率 / 1米",
        "质量 / 100KG",
        "瞬时视场 / 7x6 公里",
        "单轨工作时长 / 每轨大于30分钟"
      ])
    },
    "分割模块2":{
      "标题":"合成孔径雷达卫星",
      "描述":"高性能合成孔径雷达使您可以穿透云层、黑夜和地表，时刻获取关于地球的最新最深入信息。在农业、地貌监测、探矿、考古、自然资源管理等诸多领域提供重要信息。"
    },
    "模块简介3":{
      "名称":"物联网（IoT）卫星 WJ-3",
      "参数性能":JSON.stringify([
        "制式 / LoRa",
        "质量 / 小于30KG",
        "设计寿命 / 大于5年"
      ])
    },
    "分割模块3":{
      "标题":"天基物联网 大有作为",
      "描述":"工作于近地轨道的IoT卫星提供高速广域的IoT连接服务，与您的地面IoT网络无缝协同，真正实现万物互联。"
    }
  }
};