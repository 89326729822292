/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";

import useProgressiveImg from "@/hooks/useProgressiveImg";
import ImageBanner from "../../elements/ImageBanner";
import BannerGhostButton from "../../elements/BannerGhostButton";

import HorizontalBlock from "../../layouts/HorizontalBlock";

import css from "./style.less";
import banner3 from "../../assets/banner3.jpg";
import banner3_low from "../../assets/banner3.low.jpg";

export default function Banner3(){
  const {t}=useTranslation();
  const [current_about_banner]=useProgressiveImg({lowQualitySrc:banner3_low,highQualitySrc:banner3});
  return (
    <ImageBanner className={css.banner3} image_src={current_about_banner}>
      <HorizontalBlock title={t("轮播图部分.轮播图3.标题文字")} discription={t("轮播图部分.轮播图3.描述文字")}>
        <BannerGhostButton link="/productions/satellite">
          {t("轮播图部分.轮播图3.按钮文字")}
        </BannerGhostButton>
      </HorizontalBlock>
    </ImageBanner>
)}


Banner3.propTypes={


};
Banner3.defaultProps={


};